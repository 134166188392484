import React from "react";

import "./singleBikes.scss";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { Link, useParams, useLocation } from "react-router-dom";

import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import VerifiedIcon from '@mui/icons-material/Verified';
import PaletteIcon from '@mui/icons-material/Palette';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import { HexColorPicker } from "react-colorful";
import Modal from 'react-modal';

import * as ImageHelpers from "../../helpers/imageHelpers"
import { ref as RefStor, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase, ref, onValue, get, update, set } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';


import { database, storeage } from "../../config/config";
import UserBikeList from "../../components/userbikelist/UserBikeList";
import { ChildCareSharp, SettingsBackupRestoreOutlined, UploadFileSharp } from "@mui/icons-material";
//import { height } from "@mui/system";



// check firebase rules if backend changes work with write access


const SingleBikes = (props) => {


    // former opportunityId
   const {bikeId} = useParams()

   //const userId = id.userID
   const stateParam = useLocation().state;
   // HOW TO GET STATE FROM PARENT COMPONENT https://ui.dev/react-router-pass-props-to-link
   const location = useLocation()
   const { from } = location.state
   const userIdParent = location.state.stateParam
   const bikeKeyParent = location.state.bikeKey
  

  const [bikeData, setBikeData] = useState("")
  const [bikeBrand, setBikeBrand] = useState("")
  const [bikeModel, setBikeModell] = useState("")
  const [frameSize, setFrameSize] = useState("")
  const [bikeType, setBikeType] = useState("")
  const [bikeState, setBikeState] = useState("")
  const [bikePictureAdded, setBikePictureAdded] = useState("")
  const [bikeDirection, setBikeDirection] = useState("")
//   const [firstOpportunityForUser, setFirstOpportunityForUser] = useState("")
//   const [error, setError] = useState(null);
//   const [status, setStatus] = useState('typing');

//   const [userId, setUserId] = useState("")
//   const [userEmail, setUserEmail] = useState("");
//   const [userName, setUserName] = useState("");
// //   const [userPhoneNumer, setUserPhoneNumber] = useState("-");
//   const [userAdress, setUserAdress] = useState("");
//   const [userCountry, setUserCountry] = useState("-");
//   const [userSize, setUserSize] = useState("");
//   const[matchIdIndex, setMatchIdIndex] = useState("");

  const [favoriteLikeArray, setFavoriteLikeArray] = useState([""])
  const [favoriteDislikeArray, setFavoriteDislikeArray] = useState([""])
  const [favoritePreFavoriteArray, setFavoritePreFavoriteArray] = useState([""])

  const [favoriteInput, setFavoriteInput] = useState("")

  const [bikeDirectionRightModalIsOpen, setBikeDirectionRightModalIsOpen] = useState(false)
  const [bikeDirectionLeftModalIsOpen, setBikeDirectionLeftModalIsOpen] = useState(false)

  const [bikeIsFalseModalIsOpen, setBikeIsFalseModalIsOpen] = useState(false)
  const [verifyBikePictureModalIsOpen, setVerifyBikePictureModalIsOpen] = useState(false)



  console.log("bike ID", bikeId)
  console.log("userIdParent", userIdParent)
  console.log("bikeKeyParent", bikeKeyParent)
  // console.log("User Email", userEmail)
  // console.log("NAME OF User", userName)
  // console.log("SIZE USER", userSize)
  // console.log("User zip code", userAdress)

//   console.log("likeArray", favoriteLikeArray)
//   console.log("PreFavoriteArray", favoritePreFavoriteArray)
//   console.log("-----TEXT INPUT", favoriteInput)

console.log("HALLO")
console.log("bikeDirection HALLO", bikeDirection)

  


  
  



  useEffect(() => {
    const fetchData = async () => {


      console.log("bikeId FIRST", bikeKeyParent)
    //   let bikeIdNew = bikeId.bikes
      let list = []

      const db = database;
      const dbRef = ref(db, 'bikes/' + userIdParent + "/" + bikeKeyParent);

      onValue(dbRef, (snapshot) => {
        // snapshot.forEach((childSnapshot) => {
          const key = snapshot.key;
          const data = snapshot.val();
          const bikeDirection = String(snapshot.val().bikeDirection)
          console.log("data of all QSOs per User", data)
            // for (var i = 0; i < data.length; i++) {
                if (bikeId === data.id) {
                    setBikeData(data);
                    // setBikeBrand(data.bikeGeneralInformation.bikeBrandNew)
                    // setBikeModell(data.bikeGeneralInformation.bikeModelNew)
                    // setFrameSize(data.bikeDetailInformation.frameSize)
                    // setBikeType(data.bikeGeneralInformation.bikeTypeNew)
                    // setBikeState(data.bikeState)
                    setBikeDirection(bikeDirection)
                    
                    console.log("QSO SINGLE",data)
                }


//   });
  
});




    }

   
    
    fetchData()
  },[bikeDirection])




  const handleChange =(e) => {
    setFavoriteInput(e.target.value)
  } 




  // async function handleSubmit(e) {
  //   e.preventDefault();
  //   setStatus('submitting');
  //   // console.log("???E???SEL",e.target.value)
  //   try {
  //     //await submitForm(answer);
  //     setStatus('success');
  //   } catch (err) {
  //     setStatus('typing');
  //     setError(err);
  //   }
  //   sentOpportunityToUser()
  // }



  const handleSubmit = (event) => {
    alert('A name was submitted: ' + favoriteInput);
    console.log("________FAV ARRAY", favoritePreFavoriteArray)
    favoritePreFavoriteArray.push(favoriteInput)
    sentOpportunityToUser()
    event.preventDefault();
    
  }


  // function will display the new array on the users App UI
  const sentOpportunityToUser = () => {
    console.log("HELLO FROM 1 TYRY")
    


    // update new favoritePreFavoriteArray in Firebase
    const db = database;
    update(ref(db,"favoriteDatabase/"+ userIdParent + "/" ), {
      preFavoriteArray:favoritePreFavoriteArray,
      notLikedArray:favoriteDislikeArray,
      favoriteLikeArray:favoriteLikeArray, 
      

    })
    

  }

  const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
  }





  // FOR ALL USER OPTIONS WE HAVE FUNCTIONS HERE




  const openBikeDirectionRightModal = () => {
    setBikeDirectionRightModalIsOpen(true)
  }

  const openBikeDirectionLeftModal = () => {
    setBikeDirectionLeftModalIsOpen(true)
  }

  const closeBikeDirectionModal = () => {
    setBikeDirectionRightModalIsOpen(false)
    setBikeDirectionLeftModalIsOpen(false)
  }

  const openNoBikeModal = () => {
    setBikeIsFalseModalIsOpen(true)
  }

  const closeNoBikeModal = () => {
    setBikeIsFalseModalIsOpen(false)
  }


  const openVerifyBikePictureModal = () => {
    setVerifyBikePictureModalIsOpen(true)
  }

  const closeVerifyBikePictureModal = () => {
    setVerifyBikePictureModalIsOpen(false)
  }





  const updateBikeDirectionToLeft = () => {
    
    const db = database;

    const bikeTable = ref(db, 'bikes/' + userIdParent + "/" + bikeKeyParent + "/");
        update(bikeTable, {
            bikeDirection:'left',
        }) 

    setBikeDirectionLeftModalIsOpen(false)
  }


  const updateBikeDirectionToRight = () => {
    
    const db = database;

    const bikeTable = ref(db, 'bikes/' + userIdParent + "/" + bikeKeyParent + "/");
        update(bikeTable, {
            bikeDirection:'right',
        }) 
    setBikeDirectionRightModalIsOpen(false)
  }

  const updateNoPictureIsShown = () => {
    
    const db = database;

    const bikeTable = ref(db, 'bikes/' + userIdParent + "/" + bikeKeyParent + "/");
        update(bikeTable, {
            bikeState:'WAITING FOR PICTURE',
            bikePictureAdded:false,
            bikePictureUser:"",
        }) 
    setBikeIsFalseModalIsOpen(false)
  }

  const updateVerifyBikePicture = () => {
    
    const db = database;

    const bikeTable = ref(db, 'bikes/' + userIdParent + "/" + bikeKeyParent + "/");
        update(bikeTable, {
            bikeState:'VERIFIED',
        }) 
    setBikeIsFalseModalIsOpen(false)
  }









  return (
    <div className="single">

        
      <Sidebar />
      <div className="singleContainer">
        <Navbar />


        { bikeData ?
        <div>




                            {verifyBikePictureModalIsOpen === true ? 


                                <div>
                                    <Modal
                                        isOpen={verifyBikePictureModalIsOpen}
                                        //onAfterOpen={afterOpenModal}
                                        onRequestClose={closeVerifyBikePictureModal}
                                        style={{ content: {
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                    
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%)',
                                        },}}
                                        contentLabel="Example Modal"
                                    >

                                        <div style={{height:300,width :700,}}>
                                            <div className="title">
                                                <h1 style={{textAlign:'center'}}>Verify Bicycle Picture </h1>
                                                {/* <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                                    Send Email to User to remind it to request an appointment to the following email adress:
                                                </p>
                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    lucas.deuker@gmc.dde
                                                </p>
                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    Click here to find E-Mail Templates
                                                </p> */}
                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    Click "Verify Picture" if the User did  upload a proper Bicycle Picture! Once this is clicked, the Picture Link will Bike State will switch to "VERIFIED"
                                                </p>

                                                <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                                <div>
                                                    <button  onClick={closeVerifyBikePictureModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                                </div>
                                                <div>
                                                    <button  onClick={updateVerifyBikePicture} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Verify Picture</button>
                                                </div>
                                                </div>
                                            </div>

                                            <div>

                                            

                                            

                                            </div>
                                        </div>

                                    </Modal>
                                </div>

                                :
                                <div></div>


                            }











                    {bikeIsFalseModalIsOpen === true ? 


                            <div>
                                <Modal
                                    isOpen={bikeIsFalseModalIsOpen}
                                    //onAfterOpen={afterOpenModal}
                                    onRequestClose={closeNoBikeModal}
                                    style={{ content: {
                                    top: '50%',
                                    left: '50%',
                                    right: 'auto',
                                    bottom: 'auto',
                                
                                    marginRight: '-50%',
                                    transform: 'translate(-50%, -50%)',
                                    },}}
                                    contentLabel="Example Modal"
                                >

                                    <div style={{height:300,width :700,}}>
                                        <div className="title">
                                            <h1 style={{textAlign:'center'}}>No Bicycle shown on uploaded Picture</h1>
                                            {/* <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                                Send Email to User to remind it to request an appointment to the following email adress:
                                            </p>
                                            <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                lucas.deuker@gmc.dde
                                            </p>
                                            <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                Click here to find E-Mail Templates
                                            </p> */}
                                            <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                Click "No Picture Shown" if the User did not upload a proper Bicycle Picture! Once this is clicked, the Picture Link will be removed - the picture itself needs to be deleted manually from Storage.
                                            </p>

                                            <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                            <div>
                                                <button  onClick={closeNoBikeModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                            </div>
                                            <div>
                                                <button  onClick={updateNoPictureIsShown} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>No Picture Shown</button>
                                            </div>
                                            </div>
                                        </div>

                                        <div>

                                        

                                        

                                        </div>
                                    </div>

                                </Modal>
                            </div>

                            :
                            <div></div>


                    }
































                    {bikeDirectionRightModalIsOpen === true ? 


                    <div>
                        <Modal
                            isOpen={bikeDirectionRightModalIsOpen}
                            //onAfterOpen={afterOpenModal}
                            onRequestClose={closeBikeDirectionModal}
                            style={{ content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                           
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            },}}
                            contentLabel="Example Modal"
                        >

                            <div style={{height:160,width :700,}}>
                                <div className="title">
                                    <h1 style={{textAlign:'center'}}>Change the Bike Direction to RIGHT ➡️</h1>

                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div>
                                        <button  onClick={closeBikeDirectionModal} style={{marginTop: 0, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                    </div>
                                    <div>
                                        <button  onClick={updateBikeDirectionToRight} style={{marginTop: 0, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Save</button>
                                    </div>
                                    </div>
                                </div>

                                <div>

                                  

                                   

                                </div>
                            </div>

                        </Modal>
                    </div>

                    :
                    <div></div>


                }



                            {bikeDirectionLeftModalIsOpen === true ? 


                            <div>
                                <Modal
                            isOpen={bikeDirectionLeftModalIsOpen}
                            //onAfterOpen={afterOpenModal}
                            onRequestClose={closeBikeDirectionModal}
                            style={{ content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                           
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            },}}
                            contentLabel="Example Modal"
                        >

                            <div style={{height:160,width :700,}}>
                                <div className="title">
                                    <h1 style={{textAlign:'center'}}>Change the Bike Direction to ⬅️ LEFT</h1>

                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div>
                                        <button  onClick={closeBikeDirectionModal} style={{marginTop: 0, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                    </div>
                                    <div>
                                        <button  onClick={updateBikeDirectionToLeft} style={{marginTop: 0, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Save</button>
                                    </div>
                                    </div>
                                </div>

                                <div>

                                  

                                   

                                </div>
                            </div>

                        </Modal>
                            </div>

                            :
                            <div></div>


                            }





























      
     
        <div style={{width: '100%',
        fontSize: 24,
        color: 'gray',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', margin:20}}>
        Bike
        </div>
        <div className="top">

        <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Fahrrad Information</h1>
            <div className="item">
            <button 
                        className="itemImg"
                        onClick={() => openInNewTab(bikeData.bikePictureUser)}
                        >
              <img
                src="https://as2.ftcdn.net/v2/jpg/01/01/54/37/1000_F_101543706_g2UXLXRhqwEfeJgGYzFGEOjZcGAPuhdR.jpg"
                alt=""
                className="itemImg"
              />
              </button>

                    
              <div className="details">
                <h1 className="itemTitle"></h1>
                <div className="detailItem">
                  <span className="itemKey">Marke:</span>
                  <span className="itemValue">{bikeData.bikeGeneralInformation.bikeBrandNew}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Modell:</span>
                  <span className="itemValue">{bikeData.bikeGeneralInformation.bikeModelNew}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Rahmenhöhe:</span>
                  <span className="itemValue">{bikeData.bikeDetailInformation.frameSize}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Fahrradtyp:</span>
                  <span className="itemValue">
                    {bikeData.bikeGeneralInformation.bikeTypeNew}
                  </span>
                </div>
               
              </div>
            </div>
        </div>












        <div className="right">
        <div style={{display:'flex', flexDirection:'row'}}>
          <h1 className="title">Bicycle Picture State</h1>
          <h1 style={{
                fontSize:16,
                color:'lightgray',
                marginBottom:0,
                marginLeft:10}}>{bikeState}</h1>
        </div>

        <div style={{display:'flex', flexDirection:'row', marginTop:-20}}>
          <h1 className="title">Bicycle Picture Added</h1>
          <h1 style={{
                fontSize:16,
                color:'lightgray',
                marginBottom:0,
                marginLeft:10}}>{String(bikeData.bikePictureAdded)}</h1>
        </div>

          
                
                {/* <div className="detailItem">
                  <span >Bicycle Picture Added:</span>
                  <span >{String(bikeData.bikePictureAdded)}</span>
                </div> */}

                <div style={{marginBottom:10}}>
                  <span style={{fontSize:20, fontWeight:'900'}}>1. Add Bicycle Direction</span>
                  
                </div>

                <div style={{display:'flex', flexDirection:'row',}}>
                    <span style={{marginTop:5}}>current Direction:</span>





                    {bikeDirection === "left" ?
                        <div style={{marginLeft:40}}>
                            <div style={{width: 90, height: 30, backgroundColor:'lightgrey', borderRadius:20, border:'2px solid #72a1c5'}}>
                                <div style={{display:'flex', justifyContent:'center', paddingTop:3, color:'black'}}>
                                ⬅️  left
                                </div>
                            </div>
                        </div>

                        :
                        <div></div>
                    
                    }
                    
                    
                    
                    { bikeDirection === "right" ?

                        <div style={{marginLeft:40}}>
                        <div style={{width: 90, height: 30, backgroundColor:'grey', borderRadius:20, border:'2px solid #72a1c5'}}>
                            <div style={{display:'flex', justifyContent:'center', paddingTop:3, color:'white'}}>
                                right ➡️
                            </div>
                        </div>
                        </div>
                    :

                    <div></div>
                    }

                    


                   
                    {/* <span style={{marginLeft:6, marginTop:5}}>{bikeData.bikeDirection}</span> */}
                    <div style={{marginLeft:20, marginTop:5, fontWeight:'900'}}>
                        CHANGE DIRECTION : 
                    </div>
                    <button 
                        style={{marginLeft:40,border:'2px solid #72a1c5',borderRadius:20,backgroundColor:'lightgrey'}}
                        onClick={() => openBikeDirectionLeftModal()}
                    >
                        <div style={{width: 90, height: 30, backgroundColor:'lightgrey', borderRadius:20,}}>
                            <div style={{display:'flex', justifyContent:'center', paddingTop:3, color:'black'}}>
                            ⬅️  left
                            </div>
                        </div>
                    </button>

                    <button 
                        onClick={() => openBikeDirectionRightModal()}
                        style={{marginLeft:40,border:'2px solid #72a1c5',borderRadius:20,backgroundColor:'grey'}}
                    >
                        <div style={{width: 90, height: 30, backgroundColor:'grey', borderRadius:20,}}>
                            <div style={{display:'flex', justifyContent:'center', paddingTop:3, color:'white'}}>
                                right ➡️
                            </div>
                        </div>
                    </button>
                </div>

                <div style={{display:'flex',marginBottom:10, marginTop:30, flexDirection:'column'}}>
                  <div>
                  <span style={{fontSize:20, fontWeight:'900'}}>2. Verify Bicycle Picture</span>
                  </div>



                    <div style={{display:'flex', flexDirection:'column'}}>
                <button 
                    onClick={openVerifyBikePictureModal}
                    style={{width: 250, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15, marginTop:12}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                        VERIFY BICYCLE PICTURE
                        </div>
                    </div>
                </button>

                <button 
                    onClick={openNoBikeModal}
                    style={{width: 250, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'white', border:'1px solid red', borderRadius: 20, marginBottom: 15, marginTop:12}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'red',}}>
                        PICTURE SHOWS NO BICYCLE
                        </div>
                    </div>
                </button>
                </div>


                </div>

          </div>


























        
        </div>

      
        
        </div>

        :
        <div>
        </div>
        }





        {/* <div>
        
        BIKE KEY:  {userIdParent}
        </div> */}
       
      </div> 
    </div>
  );
};

export default SingleBikes;