import { useState } from "react"
import Modal from 'react-modal';

import { useParams } from "react-router-dom";

import TestRidingQSODashboard from "../qsoDashboard/TestRidingQSODashboard";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import SellIcon from '@mui/icons-material/Sell';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { getDatabase, ref, onValue, get, update, set } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';

import { database, storeage } from "../../../config/config";

const TestRidingWorking = (props) => {

    const qSOId = useParams()
    const qSOIdParent = qSOId.qso
  
    const userIdParent = props.userIdParent
    const bikeKeyParent = props.bikeKey
    const qSODataParent = props.qSODataParent

    const [waitingForDealVerifyModalIsOpen, setWaitingForDealVerifyModalIsOpen] = useState("")

    const [sellerDealConfirmationModalIsOpen, setSellerDealConfirmationModalIsOpen] = useState(false)
    const [noDealModalIsOpen, setNoDealModalIsOpen] = useState(false)


    const openWaitingForDealVerify = () => {
        setWaitingForDealVerifyModalIsOpen(true)
    }

    const closeWaitingForDealVerify = () => {
        setWaitingForDealVerifyModalIsOpen(false)
    }


    const openNoDealModal = () => {
        setNoDealModalIsOpen(true)
      }

      const closeNoDealModal = () => {
        setNoDealModalIsOpen(false)
      }


  


      const changeStateToWaitingForDealVerification = () => {
    
        const db = database;
    
        const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent );
            update(bikeTable, {
                qsoState:'WAITING FOR DEAL VERIFICATION',
            }) 
            setWaitingForDealVerifyModalIsOpen(false)
      }


      const changeStateToNoDealAsOutcome = () => {
    
        const db = database;
    
        const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent );
            update(bikeTable, {
                qsoState:'NO DEAL',
            }) 
            setNoDealModalIsOpen(false)
      }


    return (
          <div>






                    {waitingForDealVerifyModalIsOpen === true ? 


                        <div>
                            <Modal
                                isOpen={waitingForDealVerifyModalIsOpen}
                                //onAfterOpen={afterOpenModal}
                                onRequestClose={closeWaitingForDealVerify}
                                style={{ content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                            
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                },}}
                                contentLabel="Example Modal"
                            >

                                <div style={{height:580,width :900,}}>
                                    <div className="title">
                                        <h1 style={{textAlign:'center'}}>Change State to "WAITING FOR DEAL VERIFICATION"</h1>
                                        <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                            Change the state to "WAITING FOR DEAL VERIFICATION" if you know that the bicycle is sold based on the information of the seller or the user
                                        </p>
                                        {/* <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            The Appointment is finally set to:
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            14.02.2021
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            16:30
                                        </p> */}
                                        {/* <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            Seller Phone NUmber
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            +49 12893 1839120 
                                        </p>
                                        */}

                                        <div style={{ marginTop:70, marginBottom:70}}>
                                            <div>
                                                <div>
                                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>

                                                    
                                                        <HourglassTopIcon style={{fontSize:140}}/>
                                                        <DirectionsBikeIcon style={{fontSize:140}}/>
                                                        <SellIcon style={{fontSize:140}}/>
                                                        <VerifiedUserIcon style={{fontSize:140, color:'#6fc276'}}/>


                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        Only do this once the appointment is approved by the user - because it will impact the Users App State and change it to "TEST RIDING".
                                        </p>







                                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                        <div>
                                            <button  onClick={closeWaitingForDealVerify} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                        </div>
                                        <div>
                                            <button  onClick={changeStateToWaitingForDealVerification} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Change State</button>
                                        </div>
                                        </div>
                                    </div>

                                    <div>

                                    

                                    

                                    </div>
                                </div>

                            </Modal>
                        </div>

                        :
                        <div></div>


                    }




                    {noDealModalIsOpen === true ? 


                    <div>
                        <Modal
                            isOpen={noDealModalIsOpen}
                            //onAfterOpen={afterOpenModal}
                            onRequestClose={closeNoDealModal}
                            style={{ content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                        
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            },}}
                            contentLabel="Example Modal"
                        >

                            <div style={{height:340,width :700,}}>
                                <div className="title">
                                    <h1 style={{textAlign:'center'}}>Mark this QSO as "NO DEAL"</h1>
                                    <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                        Mark this Qualified Sales Opportunity as "NO DEAL" when the bicycle is not sold after the test ride
                                    </p>
                                    {/* <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        lucas.deuker@gmc.dde
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        Click here to find E-Mail Templates
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        After the Email is sent - Click on "Email Sent" so the state for this QSO will change to an intermediare state of "1. Reminder Sent"
                                    </p> */}

                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div>
                                        <button  onClick={closeNoDealModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                    </div>
                                    <div>
                                        <button  onClick={changeStateToNoDealAsOutcome} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Email Sent</button>
                                    </div>
                                    </div>
                                </div>

                                <div>

                                

                                

                                </div>
                            </div>

                        </Modal>
                    </div>

                    :
                    <div></div>


                    }















        
                 
                        <div>
                        


                        <div className="search">
                        
                

                        <div style={{display: 'flex', flexDirection:'row', marginBottom: 10,}}>

                        <div>
                            <div style={{marginRight:40, marginLeft:10}}>
                            
                            </div>
                        </div>
                        
                            <div>
                            <div style={{marginTop:0, marginBottom:13}}>
                            <div style={{fontSize:22, fontWeight:'1000'}}>
                            TEST RIDING
                            <p style={{backgroundColor:'#6fc276', color:'black', padding:12, borderRadius:27, width:200}}>@ {qSODataParent.meetingOpportunityObject.finalAgreedDate.finalDate} - {qSODataParent.meetingOpportunityObject.finalAgreedDate.finalTime}</p>
                            </div>
                            <div style={{marginTop:10, marginBottom: 8}}> 
                                TOOLS FOR TEST RIDING APPOINTMENT
                            </div>


                            <div style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'white', border:'1px solid #1870b5', borderRadius: 20, marginBottom: 15, marginTop:12}}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 7}}>
                                <div style={{fontSize:17, fontWeight:'900', color:'#1870b5',}}>
                                GENERATE SALES CONTRACT
                                </div>
                            </div>
                            </div>


                            <div style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'white', border:'1px solid #1870b5', borderRadius: 20, marginBottom: 15, marginTop:12}}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 7}}>
                                <div style={{fontSize:17, fontWeight:'900', color:'#1870b5',}}>
                                GENERATE CHECKLIST
                                </div>
                            </div>
                            </div>

                            <div style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'white', border:'1px solid #1870b5', borderRadius: 20, marginBottom: 15, marginTop:12}}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 7}}>
                                <div style={{fontSize:17, fontWeight:'900', color:'#1870b5',}}>
                                COPY ADRESS OF APPOINTMENT
                                </div>
                            </div>
                            </div>


                {/* 


                            <div style={{marginTop:54, marginBottom: 8}}>
                                2. ENTER APPOINTMENT OPTIONS 
                            </div>


                        
                                <div style={{fontSize:14, fontWeight:'900', color:'black', marginTop: 10}}>
                                OPTION 1: 
                                </div>


                                <div style={{fontSize:14, fontWeight:'900', color:'black', marginTop: 10}}>
                                OPTION 2: 
                                </div>


                                <div style={{fontSize:14, fontWeight:'900', color:'black', marginTop: 10}}>
                                OPTION 3: 
                                </div>
                        */}








                        
                            <div style={{marginTop:54, marginBottom: 8}}>
                                1. REMIND USER OF TEST RIDING
                            </div>
                        




                            <div style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'white', border:'1px solid #1870b5', borderRadius: 20, marginBottom: 15,}}>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 7}}>
                                <div style={{fontSize:17, fontWeight:'900', color:'#1870b5',}}>
                                SEND EMAIL REMINDER
                                </div>
                            </div>
                            </div>

                            <div style={{marginTop:54, marginBottom: 8}}>
                                2. VERIFY THE OUTCOME OF THE TEST RIDING
                            </div>
                        



                            <div style={{display:'flex', flexDirection:'column'}}>
                                <button 
                                    onClick={openWaitingForDealVerify}
                                    style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15,}}>
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                                    MARK AS "WAITING FOR DEAL VERIFICATION"
                                    </div>
                                </div>
                                </button>

                                <button 
                                    onClick={openNoDealModal}
                                    style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'white', border:'1px solid red', borderRadius: 20, marginBottom: 15,}}>
                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                    <div style={{fontSize:17, fontWeight:'900', color:'red',}}>
                                    MARK AS "NO DEAL AS OUTCOME"
                                    </div>
                                </div>
                                </button>
                            </div>

                        
                                {/* <div style={{fontSize:14, fontWeight:'900', color:'black',}}>
                                CHECKBOX: SEND REMINDER VIA EMAIL
                                </div> */}


                        
                            
                            
                            </div>

                        
                        </div>
                        </div>  











                        </div>
                        </div>

  
  
          </div>
    )
  }
  
  export default TestRidingWorking