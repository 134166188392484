
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import SellIcon from '@mui/icons-material/Sell';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';



const DealDealDashboard = () => {
    return (
          <div>
        
                  <div style={{display:'flex',flexDirection:'row'}}>
                                {/* <HourglassTopIcon style={{fontSize:140}}/>
                                <DirectionsBikeIcon style={{fontSize:140}}/> */}
                                <SellIcon style={{fontSize:140}}/>
                                <VerifiedUserIcon style={{fontSize:140, color:'#6fc276'}}/>


  
                  </div>
  
  
  
          </div>
    )
  }
  
  export default DealDealDashboard