
/// IMPORTANT WHEN INITIALIZE SECOND FIREBASE APP
// https://stackoverflow.com/questions/51521001/how-to-intiialize-two-firebase-apps

import { initializeApp } from "firebase/app";
//import { getAuth } from "firebase/auth";
import { getDatabase, ref } from "firebase/database";
import { getStorage } from "firebase/storage";



export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY_APP,
    authDomain: "cycle-app-f43de.firebaseapp.com",
    databaseURL:"https://cycle-app-f43de-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "cycle-app-f43de",
    storageBucket: "cycle-app-f43de.appspot.com",
    messagingSenderId: "507539174480",
    appId: "1:507539174480:web:869661b2d07365e8b40d55",
    measurementId: "G-7KRNNPV89X"
    
  };

const app = initializeApp(firebaseConfig, "appDb");
//const authentication = getAuth(app)

// Get a reference to the database service
const database = getDatabase(app);
const bikeDatabase = ref(database, 'bikes/')

//var database = firebase.database();

// Create a root reference
const storeage = getStorage(app);


export { 
  //authentication, 
  database, 
  bikeDatabase, 
  storeage }