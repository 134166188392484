export const userColumns = [
    { field: "bikeKey", headerName: "ID", width: 200,
   
   
    renderCell: (params) => {
        return (
          <div>
            {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
            <div>
                
               {params.row.bikeKey}
             </div>
          </div>
        );
      },
    },

    {
        field: "userId",
        headerName: "User",
        width: 240,
        renderCell: (params) => {
            return (
              <div>
                {params.row.user}
              </div>
            );
          },
        
      //   renderCell: (params) => {
      //     return (
      //       <div className="cellWithImg">
      //         {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
      //         {/* <img className="cellImg" src={params.row.highlightPictureHeight} alt="" /> */}
      //       </div>
      //     );
      //   },
      },


    // {
    //   field: "bikeKey",
    //   headerName: "Bike ID",
    //   width: 190,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row.bikeKey}
    //       </div>
    //     );
    //   },
    // //   renderCell: (params) => {
    // //     return (
    // //       <div className="cellWithImg">
    // //         {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
    // //         {/* <img className="cellImg" src={params.row.highlightPictureHeight} alt="" /> */}
    // //       </div>
    // //     );
    // //   },
    // },
    {
      field: "bikeState",
      headerName: "STATE",
      width: 200,
      renderCell: (params) => {
              return (
                <div>
                  {params.row.bikeState}
                </div>
              );
            },
    },
  
    // {
    //   field: "age",
    //   headerName: "Age",
    //   width: 100,
    // },
    // {
    //   field: "available",
    //   headerName: "Available?",
    //   width: 120,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row.available}
    //       </div>
    //     );
    //   },
    // },
    // {
    //     field: "pickUpZipCode",
    //     headerName: "Zip Code",
    //     width: 120,
    //     renderCell: (params) => {
    //       return (
    //         <div>
    //           {params.row.userPickupLocation.pickUpZipCode}
    //         </div>
    //       );
    //     },
    //   },
  ];