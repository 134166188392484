import React from "react";

import "./single.scss";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useParams } from "react-router-dom";

import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import VerifiedIcon from '@mui/icons-material/Verified';
import PaletteIcon from '@mui/icons-material/Palette';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import { HexColorPicker } from "react-colorful";
import Modal from 'react-modal';

import * as ImageHelpers from "../../helpers/imageHelpers"
import { ref as RefStor, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase, ref, onValue, get, update } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';


import { database, storeage } from "../../config/config";
import UserBikeList from "../../components/userbikelist/UserBikeList";
import { UploadFileSharp } from "@mui/icons-material";
//import { height } from "@mui/system";



// check firebase rules if backend changes work with write access


const Single = () => {

  const id = useParams()
  const userId = id.userID

  const [userData, setUserData] = useState("")
  const [bikeUserData, setBikeUserData] = useState("")
  const [firstBikeAdded, setFirstBikeAdded] = useState("❌")
  const [bikePictureAdded, setBikePictureAdded] = useState("❌")
  const [bikePictureVerified, setBikePictureVerified] = useState("❌")
  const [highlightPictureCreated, setHighlightPictureAdded] = useState("❌")
  const [appBackgroundCreated, setAppBackgroundCreated] = useState("❌")
  const [highlightColorAdded, setHighlightColorAdded] = useState("❌")

  const [bikePictureUserLink, setBikePictureUserLink] = useState("")
  const [highlightPictureLink, setHighlightPictureLink] = useState("")
  const [appBackgroundLink, setAppBackgroundLink] = useState("")
  const [highlightColor, setHighlightColor] = useState("")

  const [newColor, setNewColor] = useState(highlightColor)


  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openUploadAppBackgroundModal, setOpenUploadAppBackgroundModal] = useState(false);
  const [openUploadHighlightPictureModal, setOpenUploadHighlightPictureModal] = useState(false);
  
  const [appBackgroundImage, setAppBackgroundImage] = useState();
  const [backgroundProgress, setBackgroundProgress] = useState(0);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [highlightModalIsOpen, setHighlightModalIsOpen] = React.useState(false);
  const [imageUrls, setImageUrls] = useState([]);

  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);


  
  


  
console.log("bikePictureUser",appBackgroundImage )
  console.log("USER DATA",userData)
  console.log("color", openUploadAppBackgroundModal)



  //const newEmail = userData
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  
  


  useEffect(() => {
    const fetchData = async () => {

      let list = []

      const db = database;
      const dbRef = ref(db, 'usersInformation/' + userId);

      onValue(dbRef, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const key = snapshot.key;
          const data = snapshot.val();
          setUserData(data);
          console.log(data)

        if (data.userBikePictureAdded === true) {
          setBikePictureAdded("✅")
          get(ref(db,"bikes/" + userId + "/" )).then((snapshot)=>{
            if(snapshot.exists()){
              console.log("BIKasdasdasdasdES",snapshot.val())
              
            }
            else{
              console.log("no data found")
          }
    
    
    
          })
        }
        if (data.highlightPictureFull.length > 0) {
          setHighlightPictureAdded("✅")
          setHighlightPictureLink(data.highlightPictureFull)

        }
        if (data.highlightPictureHeight.length > 0) {
          setAppBackgroundCreated("✅")
          setAppBackgroundLink(data.highlightPictureHeight)
        }
        if (data.highlightColor.length > 0) {
          setHighlightColorAdded("✅")
          setHighlightColor(data.highlightColor)
          
        }

        
        //highlightColor

          // list.push(childData)
    // ...

   

  });
  
}, {
  onlyOnce: true
});


get(ref(db,"bikes/" + userId + "/" )).then((snapshot)=>{
  if(snapshot.exists()){
    console.log("bikesSNAPSHOT",snapshot)
    //setNoBikes(false);
    setFirstBikeAdded("✅");
    //setFirstPictureAdded(false);
    //setHighlightPictureAdded(false)
  }
  else{
    console.log("no data found")
}
})


    }

   
    
    fetchData()
  },[])


  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const openReactColorPicker = () => {
    setOpenColorPicker(true)
  }

  const closeReactColorPicker = () => {
    setOpenColorPicker(false)
  }

  const updateHighlightColor = () => {
    const db = database;
   
    update(ref(db,"usersInformation/"+ userId + "/" ), {
      highlightColor:newColor, 
      

    })
    .catch((error)=>{
        alert("unsuccessfull, error" + error);
    })
    setOpenColorPicker(false)

  }

//MODAL
  // handle drag events
const handleDrag = function(e) {
  e.preventDefault();
  e.stopPropagation();
  if (e.type === "dragenter" || e.type === "dragover") {
    setDragActive(true);
  } else if (e.type === "dragleave") {
    setDragActive(false);
  }
};

// triggers when file is dropped
const handleDrop = function(e) {
  e.preventDefault();
  e.stopPropagation();
  setDragActive(false);
  if (e.dataTransfer.files && e.dataTransfer.files[0]) {
    // handleFiles(e.dataTransfer.files);
  }
};

// triggers when file is selected with click
const handleChange = function(e) {
  e.preventDefault();
  if (e.target.files && e.target.files[0]) {
    // handleFiles(e.target.files);
    setAppBackgroundImage(e.target.files[0])
  }
};

// const onFileChange = async (appBackgroundImage) => {

//   //const storeNew = storeage

//   const userId = id.userID
//   console.log("21212",userId)
//   const newStorage = storeage
//   const file = appBackgroundImage
//   const storageRef = newStorage.RefStor()
//   const fileRef = storageRef.child(file.name)

//   await fileRef.put(file)
//   const fileUrl = await storageRef.getDownloadURL()

  
// }


///https://www.youtube.com/watch?v=pJ8LykeBDo4
const formHandler = (e) => {
  e.preventDefault()
  const file = e.target[0].files[0]
  //uploadFile(file)
}




const uploadFile = async (file) => {
  const userId = id.userID
  if(!file) return;
  const storageRef = RefStor(storeage, 'usersInformation/' + userId + "/" + userId)
  //const uploadTask = uploadBytesResumable(storageRef, file);

  

  const uploadTask = uploadBytesResumable(storageRef, file).then((snapshot) => {
    getDownloadURL(snapshot.ref)
    .then((url) => {
      setImageUrls((prev) => [...prev, url]);
    });
  });


  console.log(uploadTask)

  uploadTask.on("state_changed", (snapshot) => {
    const prog = Math.round((200) * 100 
    );
    setBackgroundProgress(prog);

  },
  (err) => console.log(err),
  () => {
    getDownloadURL(uploadTask.snapshot.ref)
    .then((url) => console.log(url))
  }

  )
  
}


// https://www.youtube.com/watch?v=xUKIQAIOfrU

const uploadFileNew = (e) => {
  const userId = id.userID
  let file = e.target.files[0]
  setAppBackgroundImage(file);
  let fileRef = RefStor(storeage, 'usersInformation/' + userId + "/" + "appBackground_" + userId  )

  const uploadTask = uploadBytesResumable(fileRef, file)

  uploadTask.on('state_changed', (snapshot) => {
    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) *100)
    
    console.log("Upload is " + progress + "% done")
    setBackgroundProgress(progress)
  })
}

// const saveFileNew = (appBackgroundImage) => {
//   const userId = id.userID
//   let fileRef = RefStor(storeage, 'usersInformation/' + userId + "/" + userId )
//   const uploadTask = uploadBytesResumable(fileRef, appBackgroundImage)

//   uploadTask.on('state_changed', (snapshot) => {
//     const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) *100)
    
//     console.log("Upload is " + progress + "% done")
//     setBackgroundProgress(progress)
//   })
// }


// const closeModal = () => {
//   setOpenUploadAppBackgroundModal(false)
// }


// For Modal 

function openModal() {
  setIsOpen(true);
}

// function afterOpenModal() {
//   // references are now sync'd and can be accessed.
//   subtitle.style.color = '#f00';
// }













// UPLOAD IMAGE

const uploadAppBackgroundImage = async( appBackgroundImage ) =>{

  
  const userId = id.userID

  // create a reference to the firbase storage
  const storeNew = storeage
  //let bikeId = bikeIdProp
  //console.log('SHOW STORAGE', bikeIdProp);

  const userIdNew = userId
  //const userIdNew = authentication.currentUser.uid;
  //let newBikeId = bikeId;
 
  console.log('new User', userIdNew)
 
 const helper = "'"
 const helper2 = "/"
  // REWRITTEN const ref = ref(db, 'bikes/' + userIdNew + newBikeRef)
  const refNew = RefStor(storeNew, 'usersInformation/' + userIdNew + helper2 + userIdNew)

  try{
    // converting to blob
    const db = database;

    const blob = await ImageHelpers.prepareBlob(appBackgroundImage)
    // correspoding to this thread, add +JSON.stringify - https://stackoverflow.com/questions/54510060/expo-react-native-there-was-a-problem-sending-log-messages
    console.log('BLOB', +JSON.stringify(blob))
    console.log("Blobbbb", blob)
    //const snapshot = await refNew.put(blob)
    // changed uploadBytes to uploadBytesResumable because of problem while uploading photo - changes made corresponding to this thread: https://github.com/firebase/firebase-js-sdk/issues/5848
    await uploadBytesResumable(refNew, blob).then((snapshot) => {
      console.log('Uploaded a blob or file!');
    });
    
    const bikeImage = {
      
  };


let downloadUrl = await getDownloadURL(refNew);
console.log(downloadUrl)
//const userIdNew = authentication.currentUser.uid;
 
// const bikesTable = refDb(db, 'bikes/' + userIdNew + "/" + bikeIdProp + "/");
// update(bikesTable, {
//     bikePictureUser: downloadUrl,
// }) 
  

const userTable = ref(db, 'usersInformation/' + userIdNew + "/" );
update(userTable, {
  highlightPictureHeight: downloadUrl,
}) 
  
  
  
 

   blob.close()

    return downloadUrl

   // const bikeId = bikeIdProp;


  }catch(error)
  {
    console.log(error)
  }
}



function closeModal() {
  setIsOpen(false);
}


function saveModal() {
  setIsOpen(false);
  //onFileChange()
  // saveFileNew()
  //uploadFile(appBackgroundImage)
  //uploadAppBackgroundImage()
}

// For Highlight Picture Modal 

function openHighlightModal() {
  setHighlightModalIsOpen(true);
}

// function afterOpenModal() {
//   // references are now sync'd and can be accessed.
//   subtitle.style.color = '#f00';
// }

function closeHighlightModal() {
  setHighlightModalIsOpen(false);
}


function saveHighlightModal() {
  setHighlightModalIsOpen(false);
}













  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div style={{width: '100%',
        fontSize: 24,
        color: 'gray',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', margin:20}}>
        User
        </div>
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                src="https://pro2-bar-s3-cdn-cf6.myportfolio.com/17be4dd08c5417027a544816a909fcf8/01b4cc12-6d30-46e1-b8d0-ab3ca15d0fad_rw_1200.png?h=078dbf9c704d63a54cb6942823239e32"
                alt=""
                className="itemImg"
              />
              <div className="details">
                <h1 className="itemTitle"></h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{userData.signUpEmail}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">-</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">
                    -
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">-</span>
                </div>
              </div>
            </div>
          </div>
         
         
          <div className="right">
          <h1 className="title">🪄 App Highlight</h1>
          <div className="details">

                <div className="detailItem" style={{display: 'flex', flexDirection:'row', marginBottom: 10}}>
                  <div>
                    <span className="itemKey">Bike added by User: </span>
                  </div>
                  <div>
                    <span style={{marginLeft: 10}} className="itemValue">{String(firstBikeAdded)}</span>
                  </div>
                  <div>
                    <span className="itemAction"></span>
                  </div>
                </div>
                
                <div className="detailItem" style={{display: 'flex', flexDirection:'row', marginBottom: 10}}>
                  <div>
                    <span className="itemKey">Picture uploaded by User: </span>
                  </div>
                  <div>
                    <span style={{marginLeft: 10}} className="itemValue">{String(bikePictureAdded)}</span>
                  </div>
                  {/* <div style={{marginLeft: 10}} className="itemAction">
                    <button style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton">
                      <VisibilityIcon style={{fontSize: 20, alignSelf:'center'}}/>
                    </button>
                  </div>
                  <div style={{marginLeft: 10}} itemAction="itemAction">
                    <button style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }}>
                      <DownloadIcon style={{fontSize: 20}}/>
                    </button>
                  </div> */}
                </div>

                {/* <div className="detailItem" style={{display: 'flex', flexDirection:'row', marginBottom: 10}}>
                  <div>
                    <span className="itemKey">Picture Upload verified: </span>
                  </div>
                  <div>
                  <span style={{marginLeft: 10}} className="itemValue">{String(bikePictureVerified)}</span>
                  </div>
                  <div style={{marginLeft: 10}} itemAction="itemAction">
                  <button style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton">
                    <VerifiedIcon style={{fontSize: 20}}/>
                  </button>
                  </div>
                  <div style={{marginLeft: 10}} itemAction="itemAction">
                    <button style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton">
                      <ThumbDownOffAltIcon style={{fontSize: 20}}/>
                    </button>
                  </div>
                </div> */}

                <div className="detailItem" style={{display: 'flex', flexDirection:'row', marginBottom: 10}}>
                  <div>
                    <span className="itemKey">Highlight Picture created: </span>
                  </div>
                  <div>
                    <span style={{marginLeft: 10}} className="itemValue">{String(highlightPictureCreated)}</span>
                  </div>
                  <div style={{marginLeft: 10}} itemAction="itemAction">
                    <div style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton">
                   
                    <button 
    style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton"
    onClick={openHighlightModal}> <UploadIcon style={{fontSize: 20}}/></button>
                   






                   
  {highlightModalIsOpen === true ? 


      <div>
    <Modal
      isOpen={highlightModalIsOpen}
      //onAfterOpen={afterOpenModal}
      onRequestClose={closeHighlightModal}
      style={{ content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },}}
      contentLabel="Example Modal"
    >
  
      
      <div className="title">
          <h1>Upload the Complete Highlight Picture here</h1>
        </div>

        <div className="body">

<form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
<input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={uploadFile} />
{/* <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
<div style={{backgroundColor:'green'}}>
  <p>Drag and drop your file here or</p>
  <button className="upload-button" onClick={onButtonClick}>Upload a file</button>
</div> 
</label> */}

<button  onClick={closeHighlightModal} style={{marginTop: 100, marginRight: 50, height: 30, width: 100, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>

<button  onClick={saveHighlightModal} style={{marginTop: 100, marginRight: 50, height: 30, width: 100, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Save</button>
{ dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
</form>



  {/* <p>The next page looks amazing. Hope you want to go there!</p> */}
</div>
      
    </Modal>
  </div>













:
<div></div>


                      }






                    </div>





                  </div>
                  <div style={{marginLeft: 10}} itemAction="itemAction">
                    { highlightPictureLink.length < 0 ?
                    <button 
                      style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton"
                      onClick={() => openInNewTab(highlightPictureLink)}
                    >
                     
                     
                      <VisibilityIcon style={{fontSize: 20}}/>
                      
                     
                    </button>
                    :
                    <div></div>
                     }
                  </div>
                </div>
             
                
                <div className="detailItem" style={{display: 'flex', flexDirection:'row', marginBottom: 10}} >
                  <div>
                    <span className="itemKey">App Background created: </span>
                  </div>
                  <div>
                    <span style={{marginLeft: 10}} className="itemValue">{String(appBackgroundCreated)}</span>
                  </div>
                  <div style={{marginLeft: 10}} itemAction="itemAction">
                    <div 
                    >
                     
                      
                      
                     
                      





   
    <button 
    style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton"
    onClick={openModal}> <UploadIcon style={{fontSize: 20}}/></button>
   

     {modalIsOpen === true ? 
      <div>
    <Modal
      isOpen={modalIsOpen}
      //onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={{ content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },}}
      contentLabel="Example Modal"
    >
  
      
      <div className="title">
          <h1>Upload the Individual App Background here</h1>
        </div>

        <div className="body">

<form id="form-file-upload" onDragEnter={handleDrag} onSubmit={formHandler}>
{/* <input ref={inputRef} type="file" id="input-file-upload" multiple={false} onChange={uploadFileNew} /> */}

{/* <input type="file" onChange={handleChange} /> */}
<input type="file" onChange={uploadFileNew} />

{/* <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
<div style={{backgroundColor:'green'}}>
  <p>Drag and drop your file here or</p>
  <button className="upload-button" onClick={onButtonClick}>Upload a file</button>
</div> 
</label> */}
{ dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
</form>

{/* { backgroundProgress > 0 ?  */}
<h3>Uploaded {backgroundProgress}% done!</h3>
{/* // :
// <div></div>
// } */}

<button  onClick={closeModal} style={{marginTop: 100, marginRight: 50, height: 30, width: 100, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>

<button type="submit" onClick={saveModal} style={{marginTop: 100, marginRight: 50, height: 30, width: 100, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Confirm</button>

  {/* <p>The next page looks amazing. Hope you want to go there!</p> */}
</div>
      
    </Modal>
  </div>













:
<div></div>


                      }
                    </div>
                  </div>
                  <div style={{marginLeft: 10}} itemAction="itemAction">
                    
                    { appBackgroundLink.length > 0 ?
                    <button 
                      style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton"
                      onClick={() => openInNewTab(appBackgroundLink)}
                    >
                     
                      <VisibilityIcon style={{fontSize: 20}}/>
                     
                    </button>
                     :
                     <div></div>
                     }
                  </div>
                </div>
               
                <div className="detailItem" style={{display: 'flex', flexDirection:'row', marginBottom: 10}}>
                  <div>
                    <span className="itemKey">Highlight Color chosen: </span>
                  </div>
                  <div>
                    <span style={{marginLeft: 10}} className="itemValue">{String(highlightColorAdded)}</span>
                  </div>
                  <div style={{marginLeft: 10}} itemAction="itemAction">
                    <button 
                      style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton"
                      onClick={() => openReactColorPicker()}
                      >
                      <PaletteIcon style={{fontSize: 20, color:highlightColor}}/>
                    </button>
                    <div>
                    {
                      openColorPicker === true ? 
                      <div style={{ flex: 1, height: 300, width: 200, borderRadius: 10, backgroundColor: "black", alignItems:'center', justifyContent: 'center'}}>
                      <HexColorPicker color={highlightColor} onChange={setNewColor} />
                      <div style={{flexDirection:'row', marginTop: 5, marginLeft: 2, marginBottom: 10}}>
                        <span style={{color:'white', marginBottom: 5}}>Chosen Color: </span>
                        <span style={{color:'white'}}> {newColor}</span>
                        
                        {/* <label>
                        <input
                          type="text"
                          id="file"
                           onChange={(e) => setNewColor(newColor)}
                          style={{ color:"black", width: 188 }}
                          placeholder={highlightColor}
                />
                </label> */}
                <div style={{flexDirection:'row', marginTop:5}}>
                 
                      <button 
                        style={{marginLeft: 30, marginRight:40}}
                        onClick={() => closeReactColorPicker()}
                        >
                        <span>X</span>
                      </button>
                  
                  
                    <button onClick={() => updateHighlightColor(newColor)}>
                      <span>Save</span>
                    </button>
                       
                </div>
                      </div>
                      </div>
                      :
                      <div>
                      </div>
                    }
                    </div>
                  </div>
                </div>
          </div>
            {/* <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" /> */}
          </div>
        </div>
        <div className="bottom">
        <h1 className="title">Bicycles</h1>
          <UserBikeList userId={userId}/>
        </div>
      </div>
    </div>
  );
};

export default Single;