import React from "react";

import "./singleOpportunities.scss";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { Link, useParams, useLocation } from "react-router-dom";

import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import VerifiedIcon from '@mui/icons-material/Verified';
import PaletteIcon from '@mui/icons-material/Palette';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import { HexColorPicker } from "react-colorful";
import Modal from 'react-modal';

import * as ImageHelpers from "../../helpers/imageHelpers"
import { ref as RefStor, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase, ref, onValue, get, update, set } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';


import { database, storeage } from "../../config/config";
import UserBikeList from "../../components/userbikelist/UserBikeList";
import { ChildCareSharp, SettingsBackupRestoreOutlined, UploadFileSharp } from "@mui/icons-material";
//import { height } from "@mui/system";



// check firebase rules if backend changes work with write access


const SingleOpportunities = () => {

   const opportunityId = useParams()

   //const userId = id.userID
   const stateParam = useLocation().state;
   // HOW TO GET STATE FROM PARENT COMPONENT https://ui.dev/react-router-pass-props-to-link
   const location = useLocation()
   const { from } = location.state
   const userIdParent = location.state.stateParam
   const bikeKey = location.state.bikeKey
  


     const [opportunityData ,setOpportunityData] = useState("")
//   const [matchData, setMatchData] = useState("")
//   const [firstOpportunityForUser, setFirstOpportunityForUser] = useState("")
//   const [error, setError] = useState(null);
//   const [status, setStatus] = useState('typing');

//   const [userId, setUserId] = useState("")
//   const [userEmail, setUserEmail] = useState("");
//   const [bikeName, setBikeName] = useState("");
//   const [bikeBrand, setBikeBrand] = useState("-");
//   const [bikeModel, setBikeModel] = useState("");
//   const [bikeType, setBikeType] = useState("-");
//   const [bikeFrameSize, setBikeFrameSize] = useState("-")
//   const [estimatedFittingSize, setEstimatedFittingSize] = useState("-")
//   const [bikeAvailable, setBikeAvailable] = useState("")
//   const [userSize, setUserSize] = useState("");
//   const[matchIdIndex, setMatchIdIndex] = useState("");

//   const [favoriteLikeArray, setFavoriteLikeArray] = useState([""])
// //   const [favoriteDislikeArray, setFavoriteDislikeArray] = useState([""])
//   const [favoritePreFavoriteArray, setFavoritePreFavoriteArray] = useState([""])

  const [favoriteInput, setFavoriteInput] = useState("")

  console.log("opportunityId", opportunityId.opportunity)
  console.log("userIdParent", userIdParent)
  console.log("bikeKey", bikeKey)
  // console.log("User Email", userEmail)
  // console.log("NAME OF User", userName)
  // console.log("SIZE USER", userSize)
  // console.log("User zip code", userAdress)

//   console.log("likeArray", favoriteLikeArray)
//   console.log("PreFavoriteArray", favoritePreFavoriteArray)
//   console.log("-----TEXT INPUT", favoriteInput)
 
  


  
  

  useEffect(() => {
    const fetchData = async () => {


    //   console.log("QSO ID in useEffect?", bikeId)
      let list = []
      let newOpportunityId = opportunityId.opportunity

      const db = database;
      const dbRef = ref(db, 'exportMarketplace/' + userIdParent + "/" + bikeKey + "/" + newOpportunityId);

      onValue(dbRef, (snapshot) => {
        // snapshot.forEach((childSnapshot) => {
          const key = snapshot.key;
          const data = snapshot.val();
          console.log("Opp Data in useEffect", data)
            // for (var i = 0; i < data.length; i++) {
                // if (newOpportunityId === data.id) {
                    setOpportunityData(data);
                    // setBikeBrand(data.bikeGeneralInformation.bikeBrandNew)
                    // setBikeModell(data.bikeGeneralInformation.bikeModelNew)
                    // setFrameSize(data.bikeDetailInformation.frameSize)
                    // setBikeType(data.bikeGeneralInformation.bikeTypeNew)
                    // setBikeState(data.bikeState)
                    // setBikeDirection(data.bikeDirection)
                    
                    console.log("QSO SINGLE",data)
                // }


//   });
  
}, {
  onlyOnce: true
});




    }

   
    
    fetchData()
  },[])



// onValue(userFavRef, (snapshot) => {

//   const keyFavs = snapshot.key;
//   const dataFavs = snapshot.val();
// //   console.log("HELLO",data)
//   //console.log(data)

// // list.push(data)
// // setMatchData(list)
// console.log("!!!users FAvs REF", dataFavs)
//   setFavoriteLikeArray(dataFavs.favoriteLikeArray)
//   setFavoriteDislikeArray(dataFavs.notLikedArray)
//   setFavoritePreFavoriteArray(dataFavs.preFavoriteArray)



// // setUserId(list[matchIdIndex])
// }, {
// onlyOnce: true
// });





//       onValue(dbRef, (snapshot) => {
       


//           const key = snapshot.key;
//           const data = snapshot.val();
//         //   console.log("HELLO",data)
//           //console.log(data)

//         // list.push(data)
//         // setMatchData(list)
//         console.log("LIST", data)
        
        
//         // setUserId(list[matchIdIndex])
// }, {
//   onlyOnce: true
// });





//     }

   
    
//     fetchData()
//   },[])




  const handleChange =(e) => {
    setFavoriteInput(e.target.value)
  } 




  // async function handleSubmit(e) {
  //   e.preventDefault();
  //   setStatus('submitting');
  //   // console.log("???E???SEL",e.target.value)
  //   try {
  //     //await submitForm(answer);
  //     setStatus('success');
  //   } catch (err) {
  //     setStatus('typing');
  //     setError(err);
  //   }
  //   sentOpportunityToUser()
  // }



//   const handleSubmit = (event) => {
//     alert('A name was submitted: ' + favoriteInput);
//     console.log("________FAV ARRAY", favoritePreFavoriteArray)
//     favoritePreFavoriteArray.push(favoriteInput)
//     sentOpportunityToUser()
//     event.preventDefault();
    
//   }


//   // function will display the new array on the users App UI
//   const sentOpportunityToUser = () => {
//     console.log("HELLO FROM 1 TYRY")
    


//     // update new favoritePreFavoriteArray in Firebase
//     const db = database;
//     update(ref(db,"favoriteDatabase/"+ userIdParent + "/" ), {
//       preFavoriteArray:favoritePreFavoriteArray,
//       notLikedArray:favoriteDislikeArray,
//       favoriteLikeArray:favoriteLikeArray, 
      

//     })
    

//   }











  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />


        { opportunityData ?
        <div>

        <div style={{width: '100%',
        fontSize: 24,
        color: 'gray',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', margin:20}}>
        Opportunity
        </div>
        <div className="top">

        <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Opportunity Information</h1>
            <div className="item">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOU2IXDNV7UBPU-nQiOsjbWjYBu-gDGWj3Fw&usqp=CAU"
                alt=""
                className="itemImg"
              />
              <div className="details">
                <h1 className="itemTitle"></h1>
                
                {/* <div className="detailItem">
                  <span className="itemKey">Name:</span>
                  <span className="itemValue">
                -
                  </span>
                </div> */}
                <div className="detailItem">
                  <span className="itemKey">Sales Price:</span>
                  <span className="itemValue">{opportunityData.sellPrice}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Available:</span>
                  <span className="itemValue">{String(opportunityData.available)}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Invoice Available:</span>
                  <span className="itemValue">{opportunityData.InvoiceAvailable}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Shipping Available:</span>
                  <span className="itemValue">{opportunityData.ShippingAvailable}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Estimated Total Kilometers:</span>
                  <span className="itemValue">{opportunityData.additionalInformation.estimatedTotalKilometers}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Last Service:</span>
                  <span className="itemValue">{opportunityData.additionalInformation.lastService}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Pre Owners:</span>
                  <span className="itemValue">{opportunityData.additionalInformation.preOwners}</span>
                </div>
              </div>
            </div>
        </div>












        <div className="right">
        {/* <div className="editButton">Edit</div> */}
            <h1 className="title">Bicycle Information</h1>
            <div style={{display: 'flex', gap:20}}>
              {/* <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOU2IXDNV7UBPU-nQiOsjbWjYBu-gDGWj3Fw&usqp=CAU"
                alt=""
                className="itemImg"
              /> */}
              <div className="details">
                <h1 className="itemTitle"></h1>
                
                {/* <div className="detailItem">
                  <span className="itemKey">Name:</span>
                  <span className="itemValue">
                -
                  </span>
                </div> */}
                <div style={{marginBottom:10,fontSize:14,flexDirection:'row',display:'flex'}}>
                  <span style={{fontWeight: 'bold',color: 'gray',marginRight:5}}>Marke:</span>
                  <span style={{fontWeight:'300'}}>{opportunityData.bikeItem.bikeGeneralInformation.bikeBrandNew}</span>
                </div>
                <div style={{marginBottom:10,fontSize:14,flexDirection:'row',display:'flex'}}>
                  <span style={{fontWeight: 'bold',color: 'gray',marginRight:5}}>Modell:</span>
                  <span style={{fontWeight:'300'}}>{opportunityData.bikeItem.bikeGeneralInformation.bikeModellNew}</span>
                </div>
                <div style={{marginBottom:10,fontSize:14,flexDirection:'row',display:'flex'}}>
                  <span style={{fontWeight: 'bold',color: 'gray',marginRight:5}}>Type:</span>
                  <span style={{fontWeight:'300'}}>{opportunityData.bikeItem.bikeGeneralInformation.bikeTypeNew}</span>
                </div>
                <div style={{marginBottom:10,fontSize:14,flexDirection:'row',display:'flex'}}>
                  <span style={{fontWeight: 'bold',color: 'gray',marginRight:5}}>Rahmenhöhe:</span>
                  <span style={{fontWeight:'300'}}>{opportunityData.bikeItem.bikeDetailInformation.frameSize}</span>
                </div>
                <div style={{marginBottom:10,fontSize:14,flexDirection:'row',display:'flex'}}>
                  <span style={{fontWeight: 'bold',color: 'gray',marginRight:5}}>Passende Körpergröße:</span>
                  <span style={{fontWeight:'300'}}>{opportunityData.estimatedPerfectFittingSize}</span>
                </div>
                <div style={{marginBottom:10,fontSize:14,flexDirection:'row',display:'flex'}}>
                  <span style={{fontWeight: 'bold',color: 'gray',marginRight:5}}>Zustand:</span>
                  <span style={{fontWeight:'300'}}>{opportunityData.condition}</span>
                </div>
              </div>
            </div>
          </div>


        
        </div>
        </div>
        :
        <div></div>
        }



        {/* <div>
        
        OPPORTUNITY ID:  {userIdParent}
        </div> */}
      
      </div> 
    </div>
  );
};

export default SingleOpportunities;