import { useState } from "react"
import Modal from 'react-modal';

import { useParams } from "react-router-dom";

import TestRidingQSODashboard from "../qsoDashboard/TestRidingQSODashboard";


import { getDatabase, ref, onValue, get, update, set } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';

import { database, storeage } from "../../../config/config";

const AppointmentApprovalWorking = (props) => {

    const qSOId = useParams()
    const qSOIdParent = qSOId.qso
  
    const userIdParent = props.userIdParent
    const bikeKeyParent = props.bikeKey
    const qSODataParent = props.qSODataParent


    const [sendAppointmentReminderToUserModalIsOpen, setSendAppointmentReminderToUserModalIsOpen] = useState(false)
    const [approveAppointmentModalIsOpen, setApproveAppointmentModalIsOpen] = useState(false)

    const openAppointmentNotificationModal = () => {
        setSendAppointmentReminderToUserModalIsOpen(true)
      }
    
    
      const closeAppointmentNotificationModal = () => {
        setSendAppointmentReminderToUserModalIsOpen(false)
      }


      const openApproveAppointmentModal = () => {
        setApproveAppointmentModalIsOpen(true)
      }
    
      const closeApproveAppointmentModal = () => {
        setApproveAppointmentModalIsOpen(false)
      }
    


      const changeStateToTestRiding = () => {
    
        const db = database;
    
        const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent );
            update(bikeTable, {
                qsoState:'TEST RIDING',
            }) 
            closeApproveAppointmentModal(false)
      }

    return (
          <div>




                {approveAppointmentModalIsOpen === true ? 


                    <div>
                        <Modal
                            isOpen={approveAppointmentModalIsOpen}
                            //onAfterOpen={afterOpenModal}
                            onRequestClose={closeApproveAppointmentModal}
                            style={{ content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                        
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            },}}
                            contentLabel="Example Modal"
                        >

                            <div style={{height:520,width :900,}}>
                                <div className="title">
                                    <h1 style={{textAlign:'center'}}>Change State to "TEST RIDING"</h1>
                                    <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                        Change the state to "APPOINTMENT APPROVAL" if the appointment is approved by the seller!
                                    </p>
                                    {/* <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        The Appointment is finally set to:
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        14.02.2021
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        16:30
                                    </p> */}
                                    {/* <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        Seller Phone NUmber
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        +49 12893 1839120 
                                    </p>
                                    */}

                                    <div style={{marginLeft:90, marginTop:70, marginBottom:70}}>
                                    <TestRidingQSODashboard/>
                                    </div>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                    Only do this once the appointment is approved by the user - because it will impact the Users App State and change it to "TEST RIDING".
                                    </p>







                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div>
                                        <button  onClick={closeApproveAppointmentModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                    </div>
                                    <div>
                                        <button  onClick={changeStateToTestRiding} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Change State</button>
                                    </div>
                                    </div>
                                </div>

                                <div>

                                

                                

                                </div>
                            </div>

                        </Modal>
                    </div>

                    :
                    <div></div>


                }







                                {sendAppointmentReminderToUserModalIsOpen === true ? 


                                <div>
                                    <Modal
                                        isOpen={sendAppointmentReminderToUserModalIsOpen}
                                        //onAfterOpen={afterOpenModal}
                                        onRequestClose={closeAppointmentNotificationModal}
                                        style={{ content: {
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                    
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%)',
                                        },}}
                                        contentLabel="Example Modal"
                                    >

                                        <div style={{height:650,width :800,}}>
                                            <div className="title">
                                                <h1 style={{textAlign:'center'}}>Send Appointment Reminder to User</h1>
                                                <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                                    Send a Notification to the user to remind it to open its app to see that the Appointment is approved:
                                                </p>
                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    Seller Email
                                                </p>
                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    lucas.deuker@gmc.dde
                                                </p>
                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    Seller Phone NUmber
                                                </p>
                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    +49 12893 1839120 
                                                </p>

                                                <p style={{ marginLeft: 80, marginTop: 33, paddingRight:80}}>
                                                    Final Appointment Date & Time
                                                </p>
                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                   24.12.2022
                                                </p>


                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    Click here to find E-Mail or Text Message Templates
                                                </p>
                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    After the Notification is sent - Click on "Notification Sent" so the state for this QSO will change to an state of "APPOINTMENT LAST CALL"
                                                </p>


                                                <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                                <div>
                                                    <button  onClick={closeAppointmentNotificationModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                                </div>
                                                <div>
                                                    <button  onClick={closeAppointmentNotificationModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Notification Sent</button>
                                                </div>
                                                </div>
                                            </div>

                                            <div>

                                            

                                            

                                            </div>
                                        </div>

                                    </Modal>
                                </div>

                                :
                                <div></div>


                                }












        
                 
                            <div>
                            


                            <div className="search">
                            
                    

                            <div style={{display: 'flex', flexDirection:'row', marginBottom: 10,}}>

                            <div>
                                <div style={{marginRight:40, marginLeft:10}}>
                                
                                </div>
                            </div>
                            
                                <div>
                                <div style={{marginTop:20, marginBottom:0}}>
                                <div style={{fontSize:22, fontWeight:'1000'}}>
                                APPOINTMENT APPROVAL
                                <p style={{backgroundColor:'#6fc276', color:'black', padding:12, borderRadius:27, width:200}}>@ {qSODataParent.meetingOpportunityObject.finalAgreedDate.finalDate} - {qSODataParent.meetingOpportunityObject.finalAgreedDate.finalTime}</p>
                                </div>

                               
                            







                            
                                <div style={{marginTop:24, marginBottom: 28}}>
                                    CONGRATULATE USER TO TEST RIDE
                                </div>
                            



                                <div style={{display:'flex', flexDirection:'column'}}>
                                    
                                    <button 
                                        onClick={openAppointmentNotificationModal}
                                        style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#e69b00', border:'1px solid black', borderRadius: 20, marginBottom: 15,}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{fontSize:17, fontWeight:'900', color:'black',}}>
                                            SEND REMINDER TO USER
                                        </div>
                                    </div>
                                    </button>

                                    <button 
                                
                                        style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#e69b00', border:'1px solid black', borderRadius: 20, marginBottom: 15,}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{fontSize:17, fontWeight:'900', color:'black',}}>
                                            SEND PUSH NOTIFICATION
                                        </div>
                                    </div>
                                    </button>
                                </div>




                                <div style={{marginTop:24, marginBottom: 28}}>
                                    OPTIONAL - CHANGE THE STATE MANUALLY TO "TEST RIDING"
                                </div>

                                <button 
                                        onClick={openApproveAppointmentModal}
                                        style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15,}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                                            CHANGE STATE TO "TEST RIDING"
                                        </div>
                                    </div>
                                </button>

                            
                                    {/* <div style={{fontSize:14, fontWeight:'900', color:'black',}}>
                                    CHECKBOX: SEND REMINDER VIA EMAIL
                                    </div> */}


                            
                                
                                
                                </div>

                            
                            </div>
                            </div>  











                            </div>
                            </div>

  
  
          </div>
    )
  }
  
  export default AppointmentApprovalWorking