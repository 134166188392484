import React, { useEffect, useState }  from "react";
import Modal from 'react-modal';

import { useParams } from "react-router-dom";

import { getDatabase, ref, onValue, get, update, set } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';

import { database, storeage } from "../../../config/config";


const WaitingForDealVerificationWorking = (props) => {

    const qSOId = useParams()
    const qSOIdParent = qSOId.qso
  
    const userIdParent = props.userIdParent
    const bikeKeyParent = props.bikeKey


    const [sendConfirmationEmailToUserModalIsOpen, setSendConfirmationEmailToUserModalIsOpen] = useState(false)
    const [sendConfirmationEmailToSellerModalIsOpen, setSendConfirmationEmailToSellerModalIsOpen] = useState(false)
    const [directAppointmentModalIsOpen, setDirectAppointmentModalIsOpen] = useState(false)

    const [userDealVerificationModalIsOpen, setUserDealVerificationModalIsOpen] = useState(false)
    const [sellerDealVerificationModalIsOpen, setSellerDealVerificationModalIsOpen] = useState(false)

    const [sellerDealConfirmationModalIsOpen, setSellerDealConfirmationModalIsOpen] = useState(false)
    const [noDealModalIsOpen, setNoDealModalIsOpen] = useState(false)

    const openSendConfirmationEmailUserModal = () => {
        setSendConfirmationEmailToUserModalIsOpen(true)
      }
    

      const closeSendConfirmationEmailUserModal = () => {
        setSendConfirmationEmailToUserModalIsOpen(false)
      }

      const openSendConfirmationEmailSellerModal = () => {
        setSendConfirmationEmailToSellerModalIsOpen(true)
      }
    

      const closeSendConfirmationEmailSellerModal = () => {
        setSendConfirmationEmailToSellerModalIsOpen(false)
      }

      const openUserDealVerifyModal = () => {
        setUserDealVerificationModalIsOpen(true)
      }

      const closeUserDealVerifyModal = () => {
        setUserDealVerificationModalIsOpen(false)
      }

      const openSellerDealVerifyModal = () => {
        setSellerDealVerificationModalIsOpen(true)
      }

      const closeSellerDealVerifyModal = () => {
        setSellerDealVerificationModalIsOpen(false)
      }


      const openDealConfirmationModal = () => {
        setSellerDealConfirmationModalIsOpen(true)
      }

      const closeDealConfirmationModal = () => {
        setSellerDealConfirmationModalIsOpen(false)
      }
     

    //   const openNoDealModal = () => {
    //     setNoDealModalIsOpen(true)
    //   }

    //   const closeNoDealModal = () => {
    //     setNoDealModalIsOpen(false)
    //   }



      const confirmDealModal = () => {
    
        const db = database;
    
        const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/"  + qSOIdParent );
            update(bikeTable, {
                qsoState:'DEAL',
            }) 
            closeDealConfirmationModal(false)
      }


    //   const changeStateToNoDealAsOutcome = () => {
    
    //     const db = database;
    
    //     const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + bikeKeyParent + "/" + qSOIdParent );
    //         update(bikeTable, {
    //             sqoState:'NO DEAL',
    //         }) 
    //         setNoDealModalIsOpen(false)
    //   }

   


    return (
          <div>
        








                

















                {sendConfirmationEmailToUserModalIsOpen === true ? 


                    <div>
                        <Modal
                            isOpen={sendConfirmationEmailToUserModalIsOpen}
                            //onAfterOpen={afterOpenModal}
                            onRequestClose={closeSendConfirmationEmailUserModal}
                            style={{ content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                        
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            },}}
                            contentLabel="Example Modal"
                        >

                            <div style={{height:390,width :700,}}>
                                <div className="title">
                                    <h1 style={{textAlign:'center'}}>Send Confirmation Email to User</h1>
                                    <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                        Send Email to User to remind it to request an appointment to the following email adress:
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        lucas.deuker@gmc.dde
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        Click here to find E-Mail Templates
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        After the Email is sent - Click on "Email Sent" so the state for this QSO will change to an intermediare state of "1. Reminder Sent"
                                    </p>

                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div>
                                        <button  onClick={closeSendConfirmationEmailUserModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                    </div>
                                    <div>
                                        <button  onClick={closeSendConfirmationEmailUserModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Email Sent</button>
                                    </div>
                                    </div>
                                </div>

                                <div>

                                

                                

                                </div>
                            </div>

                        </Modal>
                    </div>

                    :
                    <div></div>


                }



                {sendConfirmationEmailToSellerModalIsOpen === true ? 


                    <div>
                        <Modal
                            isOpen={sendConfirmationEmailToSellerModalIsOpen}
                            //onAfterOpen={afterOpenModal}
                            onRequestClose={closeSendConfirmationEmailSellerModal}
                            style={{ content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                        
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            },}}
                            contentLabel="Example Modal"
                        >

                            <div style={{height:390,width :700,}}>
                                <div className="title">
                                    <h1 style={{textAlign:'center'}}>Send Confirmation Email to Seller</h1>
                                    <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                        Send Email to User to remind it to request an appointment to the following email adress:
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        lucas.deuker@gmc.dde
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        Click here to find E-Mail Templates
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        After the Email is sent - Click on "Email Sent" so the state for this QSO will change to an intermediare state of "1. Reminder Sent"
                                    </p>

                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div>
                                        <button  onClick={closeSendConfirmationEmailSellerModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                    </div>
                                    <div>
                                        <button  onClick={closeSendConfirmationEmailSellerModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Email Sent</button>
                                    </div>
                                    </div>
                                </div>

                                <div>

                                

                                

                                </div>
                            </div>

                        </Modal>
                    </div>

                    :
                    <div></div>


                }












                    {userDealVerificationModalIsOpen === true ? 


                        <div>
                            <Modal
                                isOpen={userDealVerificationModalIsOpen}
                                //onAfterOpen={afterOpenModal}
                                onRequestClose={closeUserDealVerifyModal}
                                style={{ content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                            
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                },}}
                                contentLabel="Example Modal"
                            >

                                <div style={{height:390,width :700,}}>
                                    <div className="title">
                                        <h1 style={{textAlign:'center'}}>User Deal Verification</h1>
                                        <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                            Verify the Deal from the Users perspective
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                CHECKBOX VERIFY DEAL
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                               Enter Deal Date
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                ....
                                        </p>

                                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                        <div>
                                            <button  onClick={closeUserDealVerifyModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                        </div>
                                        <div>
                                            <button  onClick={closeUserDealVerifyModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Verify Deal</button>
                                        </div>
                                        </div>
                                    </div>

                                    <div>

                                    

                                    

                                    </div>
                                </div>

                            </Modal>
                        </div>

                        :
                        <div></div>


                    }


                    {sellerDealVerificationModalIsOpen === true ? 


                            <div>
                                <Modal
                                    isOpen={sellerDealVerificationModalIsOpen}
                                    //onAfterOpen={afterOpenModal}
                                    onRequestClose={closeSellerDealVerifyModal}
                                    style={{ content: {
                                    top: '50%',
                                    left: '50%',
                                    right: 'auto',
                                    bottom: 'auto',
                                
                                    marginRight: '-50%',
                                    transform: 'translate(-50%, -50%)',
                                    },}}
                                    contentLabel="Example Modal"
                                >

                                    <div style={{height:390,width :700,}}>
                                        <div className="title">
                                            <h1 style={{textAlign:'center'}}>Seller Deal Verification</h1>
                                            <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                                Verify the Deal from the Sellers perspective
                                            </p>
                                            <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                CHECKBOX VERIFY DEAL
                                            </p>
                                            <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                               Enter Deal Date
                                            </p>
                                            <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                ....
                                            </p>

                                            <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                            <div>
                                                <button  onClick={closeSellerDealVerifyModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                            </div>
                                            <div>
                                                <button  onClick={closeSellerDealVerifyModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Verify Deal</button>
                                            </div>
                                            </div>
                                        </div>

                                        <div>

                                        

                                        

                                        </div>
                                    </div>

                                </Modal>
                            </div>

                            :
                            <div></div>


                    }





                    {sellerDealConfirmationModalIsOpen === true ? 


                    <div>
                        <Modal
                            isOpen={sellerDealConfirmationModalIsOpen}
                            //onAfterOpen={afterOpenModal}
                            onRequestClose={closeDealConfirmationModal}
                            style={{ content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                        
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            },}}
                            contentLabel="Example Modal"
                        >

                            <div style={{height:390,width :700,}}>
                                <div className="title">
                                    <h1 style={{textAlign:'center'}}>Attention: Deal Confirmation 🎉</h1>
                                    <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                        This step is the final Deal Confirmation. The State will change to "DEAL" and within the orders Tab the bike can get transformed into the Users Passport!
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        CHECKBOX CONFIRM DEAL
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                    Enter Deal Confirmation Date
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        31.12.2022
                                    </p>

                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div>
                                        <button  onClick={closeDealConfirmationModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                    </div>
                                    <div>
                                        <button  onClick={confirmDealModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Deal Confirmation</button>
                                    </div>
                                    </div>
                                </div>

                                <div>

                                

                                

                                </div>
                            </div>

                        </Modal>
                    </div>

                    :
                    <div></div>


                    }









                        {/* {directAppointmentModalIsOpen === true ? 


                        <div>
                            <Modal
                                isOpen={directAppointmentModalIsOpen}
                                //onAfterOpen={afterOpenModal}
                                onRequestClose={closeDirectAppointmentModal}
                                style={{ content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                            
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                },}}
                                contentLabel="Example Modal"
                            >

                                <div style={{height:640,width :700,}}>
                                    <div className="title">
                                        <h1 style={{textAlign:'center', paddingLeft:120, paddingRight:120}}>Send Appointment Options from Seller to User</h1>
                                        <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                            Send an Appointment Opportunity from the seller to the user. Enter a date and time in the following Format DD.MM.YYYY HH:MM(24 hour format) - e.g.: 28.02.2004 12:15
                                        </p>
                                        
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            1. Appointment Option
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            22.12.2022 15:30
                                        </p> 

                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            2. Appointment Option
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            26.12.2022 18:00
                                        </p> 

                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                         This Step will automatically switch the State to "" after you press "Sent to User. 
                                        </p>

                                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                        <div>
                                            <button  onClick={closeDirectAppointmentModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                        </div>
                                        <div>
                                            <button  onClick={closeDirectAppointmentModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Sent to User</button>
                                        </div>
                                        </div>
                                    </div>

                                    <div>

                                    

                                    

                                    </div>
                                </div>

                            </Modal>
                        </div>

                        :
                        <div></div>


                        } */}












                 
                <div>
                


                <div className="search">
                
        

                <div style={{display: 'flex', flexDirection:'row', marginBottom: 10,}}>

                <div>
                    <div style={{marginRight:40, marginLeft:10}}>
                    
                    </div>
                </div>
                
                    <div>
                    <div style={{marginTop:0, marginBottom:13}}>
                    <div style={{fontSize:22, fontWeight:'1000'}}>
                    WAITING FOR DEAL VERIFICATION
                    </div>
                    <div style={{marginTop:10, marginBottom: 8}}>
                        1. SEND CONFIRMATION EMAIL TO USER AND OR SELLER
                    </div>


                    <div style={{display:'flex', flexDirection:'column'}}>
                    <button 
                        onClick={openSendConfirmationEmailUserModal}
                        style={{width: 340, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#e69b00', border:'1px solid black', borderRadius: 20, marginBottom: 15, marginTop:12}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'black',}}>
                        SEND CONFIRMATION REQUEST TO USER
                        </div>
                    </div>
                    </button>

                    <button 
                        onClick={openSendConfirmationEmailSellerModal}
                        style={{width: 340, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#e69b00', border:'1px solid black', borderRadius: 20, marginBottom: 15,}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'black',}}>
                        SEND CONFIRMATION REQUEST TO SELLER
                        </div>
                    </div>
                    </button>
                    </div>

                    <div style={{marginTop:10, marginBottom: 8}}>
                        2. VERIFY DEAL FROM BOTH PERSPECTIVES - SELLER AND USER
                    </div>


                    <div style={{display:'flex', flexDirection:'column'}}>
                    <button 
                        onClick={openUserDealVerifyModal}
                        style={{width: 340, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15, marginTop:12}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                        USER DEAL VERIFICATION
                        </div>
                    </div>
                    </button>

                    <button 
                        onClick={openSellerDealVerifyModal}
                        style={{width: 340, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15,}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                        SELLER DEAL VERIFICATION
                        </div>
                    </div>
                    </button>
                    </div>


                    <div style={{marginTop:10, marginBottom: 8}}>
                        3. FINALLY CONFIRM THE QUALIFIED SALES OPPORTUNITY AS A DEAL
                    </div>


                    <button 
                         onClick={openDealConfirmationModal}
                        style={{width: 340, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#6fc276', border:'1px solid white', borderRadius: 20, marginBottom: 15,}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                        CONFIRM DEAL
                        </div>
                    </div>
                    </button>

                
                    
                    
                    </div>

                
                </div>
                </div>  











                </div>
                </div>

  
  
          </div>
    )
  }
  
  export default WaitingForDealVerificationWorking