import "./openOpportunityList.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";

import VisibilityIcon from '@mui/icons-material/Visibility';


import { getDatabase, ref, onValue, get, update } from "firebase/database";
import { database } from "../../config/config";

const OpenOpportunityList = ({userId}) => {
  
    // console.log("USERID HELLEOL HELLO",userId)


    const [userBikesData, setUserBikesData] = useState([]);
    console.log("!!!???USERBIKEDATA",userBikesData);
    // console.log("!!!???USERBIKEDATA",userBikesData.bikeKey);
    

//     useEffect(() => {

//         const db = database;
//         get(ref(db,"bikes/" + userId + "/" )).then((snapshot)=>{
//             setUserBikesData(snapshot)
            
    
//     })
// })

useEffect(() => {
    const fetchData = async () => {

      let list = []

      const db = database;
      const dbRef = ref(db, 'exportMarketplace/' + userId);

      onValue(dbRef, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            childSnapshot.forEach((groupSnapshot) => {

          const childKey = groupSnapshot.key;
          const childData = groupSnapshot.val();
          console.log("Opportunities", childData)
          list.push(childData)
    // ...
    });
  });
  setUserBikesData(list);
}, {
  onlyOnce: true
});

    }
    
    fetchData()
  },[])

    
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  





  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Opportunity ID</TableCell>
            <TableCell className="tableCell">Bike Type</TableCell>
            <TableCell className="tableCell">Condition</TableCell>
            <TableCell className="tableCell">Estimated Fitting Size</TableCell>
            <TableCell className="tableCell">Price</TableCell>
            <TableCell className="tableCell">Available</TableCell>
            <TableCell className="tableCell">ZIP Code</TableCell>
            {/* <TableCell className="tableCell">Customer</TableCell> */}
            {/* <TableCell className="tableCell">Date</TableCell>
            <TableCell className="tableCell">Amount</TableCell>
            <TableCell className="tableCell">Payment Method</TableCell> */}
            {/* <TableCell className="tableCell">Picture Upload Link</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {userBikesData.map((row) => (
            <TableRow key={row.bikeKey}>
              <TableCell className="tableCell">
                {row.bikeKey}
                </TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  {/* <img src={row.img} alt="" className="image" /> */}
                  <div style={{marginLeft: 10}} className="itemAction">
                    <button 
                        style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton"
                        onClick={() => openInNewTab(row.bikePictureUser)}
                        >
                      <VisibilityIcon style={{fontSize: 20, alignSelf:'center'}}/>
                    </button>
                  </div>
               

                </div>
              </TableCell>
              {/* <TableCell className="tableCell">{row.customer}</TableCell> */}
              {/* <TableCell className="tableCell">{row.date}</TableCell>
              <TableCell className="tableCell">{row.amount}</TableCell>
              <TableCell className="tableCell">{row.method}</TableCell> */}
              {/* <TableCell className="tableCell">
                <span className={`status ${row.status}`}>{row.status}</span>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OpenOpportunityList;