
import React, { useEffect, useState }  from "react";
import Modal from 'react-modal';

import { useParams } from "react-router-dom";

import AppointmentApprovalQSODashboard from "../qsoDashboard/AppointmentApprovalQSODashboard";
import AppointmentDeniedQSODashboard from "../qsoDashboard/AppointmentDeniedQSODashboard";

import { getDatabase, ref, onValue, get, update, set } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';

import { database, storeage } from "../../../config/config";


const AppointmentLastCallWorking = (props) => {

    const qSOId = useParams()
    const qSOIdParent = qSOId.qso
  
    const userIdParent = props.userIdParent
    const bikeKeyParent = props.bikeKey
    const qSODataParent = props.qSODataParent

    const firstAppointmentDate = props.qSODataParent.meetingOpportunityObject.additionalMeetingOpportunityTwo.dateFirstOption
    const firstAppointmentTime = props.qSODataParent.meetingOpportunityObject.additionalMeetingOpportunityTwo.timeFirstOption

    const secondAppointmentDate = props.qSODataParent.meetingOpportunityObject.additionalMeetingOpportunityTwo.dateSecondOption
    const secondAppointmentTime = props.qSODataParent.meetingOpportunityObject.additionalMeetingOpportunityTwo.timeSecondOption

    const thirdAppointmentDate = props.qSODataParent.meetingOpportunityObject.additionalMeetingOpportunityTwo.dateThirdOption
    const thirdAppointmentTime = props.qSODataParent.meetingOpportunityObject.additionalMeetingOpportunityTwo.timeThirdOption

    const [sendRequestNotificationToSellerModalIsOpen, setSendRequestNotificationToSellerModalIsOpen] = useState(false)
    const [approveAppointmentModalIsOpen, setApproveAppointmentModalIsOpen] = useState(false)
    const [denyAppointmentModalIsOpen, setDenyAppointmentModalIsOpen] = useState(false)

    const [sendAppointmentOptionsToUserModalIsOpen, setSendAppointmentOptionsToUserModalIsOpen] = useState(false)

    const [open, setOpen] = useState(false);
    const [finalAppointmentDate, setFinalAppointmentDate] = useState("CHOOSE")
    const [finalAppointmentTime, setFinalAppointmentTime] = useState("APPOINTMENT")



    // const openRequestNotificationModal = () => {
    //     setSendRequestNotificationToSellerModalIsOpen(true)
    //   }
    
    
    //   const closeRequestNotificationModal = () => {
    //     setSendRequestNotificationToSellerModalIsOpen(false)
    //   }
    




      const openAppointmentOptionsModal = () => {
        setSendAppointmentOptionsToUserModalIsOpen(true)
    }

    const closeAppointmentOptionsModal = () => {
        setSendAppointmentOptionsToUserModalIsOpen(false)
    }



        const openApproveAppointmentModal = () => {
            setApproveAppointmentModalIsOpen(true)
        }

        const closeApproveAppointmentModal = () => {
            setApproveAppointmentModalIsOpen(false)
        }

        const openDenyAppointmentModal = () => {
            setDenyAppointmentModalIsOpen(true)
        }

        const closeDenyAppointmentModal = () => {
            setDenyAppointmentModalIsOpen(false)
        }




        const updateApproveAppointment = () => {
    
            const db = database;
        
            const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent );
            const bikeTableFirstChild = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent + "/" + "meetingOpportunityObject/" + "finalAgreedDate");

                update(bikeTable, {
                    qsoState:'APPOINTMENT APPROVAL',
                    thirdRequestedMeetingAgreedByPotBuyer:"true"
                })

                update(bikeTableFirstChild,{
                    finalDate:finalAppointmentDate,
                    finalTime:finalAppointmentTime,
                    isTimeFinallyAgreed:true
                })
                // update(bikeTableSecondChild, {
                //     qsoState:'APPOINTMENT APPROVAL',
                // }) 
                closeApproveAppointmentModal(false)
          }
        
        
          const updateDenyAppointment = () => {
            
            const db = database;
        
            const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent );
            const bikeTableSecondChild = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent + "/" + "meetingOpportunityObject/" + "additionalMeetingOpportunityOne");
            const bikeTableThirdChild = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent + "/" + "meetingOpportunityObject/" + "additionalMeetingOpportunityTwo");
                update(bikeTable, {
                    qsoState:'APPOINTMENT DENIED',
                }) 

                update(bikeTableSecondChild, {
                    additionalMeetingOpportunityOne:{
                        dateFirstOption:"",
                        timeFirstOption:"",
                        dateSecondOption:"",
                        timeSecondOption:"",
                        dateThirdOption:"",
                        timeThirdOption:"",
                    },
                }) 
                update(bikeTableThirdChild, {
                    additionalMeetingOpportunityTwo:{
                        dateFirstOption:"",
                        timeFirstOption:"",
                        dateSecondOption:"",
                        timeSecondOption:"",
                        dateThirdOption:"",
                        timeThirdOption:"",
                    },
                }) 
                closeDenyAppointmentModal(false)
          }




          const handleOpen = () => {
            setOpen(!open);
        };
    
        const handleMenuOne = () => {
            setFinalAppointmentDate(firstAppointmentDate)
            setFinalAppointmentTime(firstAppointmentTime)
            setOpen(false);
          };
        
          const handleMenuTwo = () => {
            setFinalAppointmentDate(secondAppointmentDate)
            setFinalAppointmentTime(secondAppointmentTime)
            setOpen(false);
          };
    
          const handleMenuThree = () => {
            setFinalAppointmentDate(thirdAppointmentDate)
            setFinalAppointmentTime(thirdAppointmentTime)
            setOpen(false);
          };
    
    
    
            const Dropdown = ({ open, trigger, menu }) => {
                return (
                <div className="dropdown">
                    {trigger}
                    {open ? (
                    <ul className="menu">
                        {menu.map((menuItem, index) => (
                        <li key={index} className="menu-item">{menuItem}</li>
                        ))}
                    </ul>
                    ) : null}
                </div>
                );
            };




    return (
          <div>
        
                 

                
                
                
                
                
                
                    {approveAppointmentModalIsOpen === true ? 


                        <div>
                            <Modal
                                isOpen={approveAppointmentModalIsOpen}
                                //onAfterOpen={afterOpenModal}
                                onRequestClose={closeApproveAppointmentModal}
                                style={{ content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                            
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                },}}
                                contentLabel="Example Modal"
                            >

                                <div style={{height:600,width :900,}}>
                                    <div className="title">
                                        <h1 style={{textAlign:'center'}}>Approve the Appointment</h1>
                                        <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                            Change the state to "APPOINTMENT APPROVAL" if the appointment is approved by the seller!
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            The Appointment is finally set to:
                                        </p>
                                        <div style={{marginLeft:80, marginBottom:60}}>
                                        <Dropdown
                                           
                                            open={open}
                                            trigger={<button onClick={handleOpen}>{finalAppointmentDate} {finalAppointmentTime}</button>}
                                            menu={[
                                                <button onClick={handleMenuOne}>{firstAppointmentDate} - {firstAppointmentTime}</button>,
                                                <button onClick={handleMenuTwo}>{secondAppointmentDate} - {secondAppointmentTime}</button>,
                                                <button onClick={handleMenuThree}>{thirdAppointmentDate} - {thirdAppointmentTime}</button>,
                                            ]}
                                        />
                                        </div>
                                        {/* <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            Seller Phone NUmber
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            +49 12893 1839120 
                                        </p>
                                        */}

                                        <div style={{marginLeft:90, marginTop:40, marginBottom:40}}>
                                        <AppointmentApprovalQSODashboard/>
                                        </div>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        Only do this once the appointment is approved by the seller - because it will Users App State
                                        </p>







                                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                        <div>
                                            <button  onClick={closeApproveAppointmentModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                        </div>
                                        <div>
                                            <button  onClick={updateApproveAppointment} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Approve Appointment</button>
                                        </div>
                                        </div>
                                    </div>

                                    <div>

                                    

                                    

                                    </div>
                                </div>

                            </Modal>
                        </div>

                        :
                        <div></div>


                    }





                    {denyAppointmentModalIsOpen === true ? 


                        <div>
                        <Modal
                        isOpen={denyAppointmentModalIsOpen}
                        //onAfterOpen={afterOpenModal}
                        onRequestClose={closeDenyAppointmentModal}
                        style={{ content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',

                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        },}}
                        contentLabel="Example Modal"
                        >

                        <div style={{height:600,width :900,}}>
                        <div className="title">
                        <h1 style={{textAlign:'center'}}>Deny the Appointment</h1>
                        <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                        Change the state to "APPOINTMENT DENIED" if the appointment is approved by the seller!
                        </p>
                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                        Add 3 options here:
                        </p>
                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                        1. option
                        </p>
                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                        2. option
                        </p>
                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                        3. option
                        </p>
                        {/* <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                        Seller Phone NUmber
                        </p>
                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                        +49 12893 1839120 
                        </p>
                        */}

                        <div style={{marginLeft:90, marginTop:40, marginBottom:40}}>
                        <AppointmentDeniedQSODashboard/>
                        </div>
                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                        Only do this once the appointment is approved by the seller - because it will Users App State
                        </p>







                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                        <div>
                        <button  onClick={closeDenyAppointmentModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                        </div>
                        <div>
                        <button  onClick={updateDenyAppointment} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Approve Appointment</button>
                        </div>
                        </div>
                        </div>

                        <div>





                        </div>
                        </div>

                        </Modal>
                        </div>

                        :
                        <div></div>


                    }
                                        
                                        
                
                
                
                
                
              













                    {sendAppointmentOptionsToUserModalIsOpen === true ? 


                        <div>
                            <Modal
                                isOpen={sendAppointmentOptionsToUserModalIsOpen}
                                //onAfterOpen={afterOpenModal}
                                onRequestClose={closeAppointmentOptionsModal}
                                style={{ content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                            
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                },}}
                                contentLabel="Example Modal"
                            >

                                <div style={{height:730,width :800,}}>
                                    <div className="title">
                                        <h1 style={{textAlign:'center'}}>Send Appointment Options to User</h1>
                                        <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                            Send a Notification to the seller to remind it to answer the requested appointment option made by the user:
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            Seller Email
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            lucas.deuker@gmc.dde
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            Seller Phone NUmber
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            +49 12893 1839120 
                                        </p>

                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        See the options here:
                                    </p>

                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        1. option
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        2. option
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        3. option
                                    </p>


                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            Click here to find E-Mail or Text Message Templates
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            After the Notification is sent - Click on "Notification Sent" so the state for this QSO will change to an state of "APPOINTMENT LAST CALL"
                                        </p>


                                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                        <div>
                                            <button  onClick={closeAppointmentOptionsModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                        </div>
                                        <div>
                                            <button  onClick={closeAppointmentOptionsModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Notification Sent</button>
                                        </div>
                                        </div>
                                    </div>

                                    <div>

                                    

                                    

                                    </div>
                                </div>

                            </Modal>
                        </div>

                        :
                        <div></div>


                    }




























                                <div>
                                


                                <div className="search">
                                
                        

                                <div style={{display: 'flex', flexDirection:'row', marginBottom: 10,}}>

                                <div>
                                    <div style={{marginRight:40, marginLeft:10}}>
                                    
                                    </div>
                                </div>
                                
                                    <div>
                                    <div style={{marginTop:0, marginBottom:13}}>
                                    <div style={{fontSize:22, fontWeight:'1000'}}>
                                    APPOINTMENT LAST CALL
                                    </div>

                                    
                                        <div style={{marginTop:10, marginBottom: 8}}>
                                            APPOINTMENT OPTIONS FROM SELLER
                                        </div>


                                    
                                        <div style={{fontSize:14, fontWeight:'900', color:'black', marginTop: 10}}>
                                        OPTION 1: {firstAppointmentDate} - {firstAppointmentTime}
                                        </div>


                                        <div style={{fontSize:14, fontWeight:'900', color:'black', marginTop: 10}}>
                                        OPTION 2: {secondAppointmentDate} - {secondAppointmentTime}
                                        </div>


                                        <div style={{fontSize:14, fontWeight:'900', color:'black', marginTop: 10}}>
                                        OPTION 3: {thirdAppointmentDate} - {thirdAppointmentTime}
                                        </div>




                                    <div style={{marginTop:30, marginBottom: 8}}> 
                                        1. SEND APPOINTMENT OPTIONS TO USER
                                    </div>


                                    <button 
                                        onClick={openAppointmentOptionsModal}
                                        style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#e69b00', border:'1px solid black', borderRadius: 20, marginBottom: 15,}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{fontSize:17, fontWeight:'900', color:'black',}}>
                                        SEND OPTIONS TO USER
                                        </div>
                                    </div>
                                    </button>










                                    <div style={{marginTop:54, marginBottom: 8}}>
                                        2. TAKE ACTION BASED ON USERS DECISION
                                    </div>
                                    <div style={{marginTop:10, marginBottom: 8}}>
                                        FOR NEGATIVE SELLER FEEDBACK
                                    </div>


                                    <button 
                                    onClick={openDenyAppointmentModal}
                                    style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'white', border:'1px solid #1870b5', borderRadius: 20, marginBottom: 15,}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{fontSize:17, fontWeight:'900', color:'#1870b5',}}>
                                        DENY THE APPOINTMENT
                                        </div>
                                    </div>
                                    </button>


                                    <div style={{marginTop:10, marginBottom: 8}}>
                                        FOR POSITIVE SELLER FEEDBACK
                                    </div>


                                    <button 
                                    onClick={openApproveAppointmentModal}
                                    style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15,}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                                        APPROVE THE APPOINTMENT
                                        </div>
                                    </div>
                                    </button>


                                    {/* <div style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15,}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 7}}> */}
                                        {/* <div style={{fontSize:14, fontWeight:'900', color:'black',}}>
                                        CHECKBOX: SEND REMINDER VIA EMAIL
                                        </div> */}
                                    {/* </div>
                                    </div> */}

                                
                                    
                                    
                                    </div>

                                
                                </div>
                                </div>  











                                </div>
                                </div>

                        
  
          </div>
    )
  }
  
  export default AppointmentLastCallWorking