import "./listOpportunities.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
//import DatatableOpportunities from "../../components/datatable/DatatableOpportunities"
import DatatableOpportunity from "../../components/datatableOpportunity/DatatableOpportunity"

const ListOpportunities = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        {/* <DatatableOpportunities/> */}
        <DatatableOpportunity/>
      </div>
    </div>
  )
}

export default ListOpportunities