export const userColumns = [
    { field: "id", headerName: "ID", width: 200,
   
   
    renderCell: (params) => {
        return (
          <div>
            {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
            <div>
               {params.row.id}
             </div>
          </div>
        );
      },
    },


    {
      field: "bikeKey",
      headerName: "Bicycle",
      width: 190,
    //   renderCell: (params) => {
    //     return (
    //       <div className="cellWithImg">
    //         {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
    //         {/* <img className="cellImg" src={params.row.highlightPictureHeight} alt="" /> */}
    //       </div>
    //     );
    //   },
    },
    {
      field: "sellPrice",
      headerName: "Price",
      width: 70,
    },
  
    // {
    //   field: "age",
    //   headerName: "Age",
    //   width: 100,
    // },
    {
      field: "available",
      headerName: "Available",
      width: 120,
      // renderCell: (params) => {
      //   return (
      //     <div>
      //       {params.row.available}
      //     </div>
      //   );
      // },
    },
    {
      field: "condition",
      headerName: "Condition",
      width: 120,
    },
    {
      field: "size",
      headerName: "Size",
      width: 120,
      renderCell: (params) => {
        return (
          <div>
            {params.row.estimatedPerfectFittingSize}
          </div>
        );
      },
    },
    {
        field: "pickUpZipCode",
        headerName: "Zip Code",
        width: 120,
        renderCell: (params) => {
          return (
            <div>
              {params.row.userPickupLocation.pickUpZipCode}
            </div>
          );
        },
      },
  ];