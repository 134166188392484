import "./datatableOpportunity.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns, userRows } from "./datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import { getDatabase, ref, onValue, get, } from "firebase/database";
import { database } from "../../config/config";


const DatatableOpportunity = () => {
  const [data, setData] = useState([]);
  console.log("DARA",data)
  const [toUserDetail, setToUserDetail] = useState("");


  useEffect(() => {
    const fetchData = async () => {

      let list = []

      const db = database;
      const dbRef = ref(db, 'exportMarketplace');

      onValue(dbRef, (snapshot) => {
        console.log("snapshot", snapshot)
        snapshot.forEach((grandSnapshot) => {
            grandSnapshot.forEach((groupSnapshot) => {
                groupSnapshot.forEach((childSnapshot) => {

          const childKey = childSnapshot.key;
          
          const childData = childSnapshot.val()

          console.log("GRIPU ", childData)
          console.log("KEY ", childKey)
        //   const secondChildData = childData.childSnapshot.child("makers0");
        //   console.log("childSnapshot", secondChildData);
        //   const thirdChildData = childSnapshot.child(secondChildData);
        //   const thirdChildKey = childSnapshot.child(secondChildData).key;
        //   console.log("childata", thirdChildData)
         list.push(childData)



        //   let bike0Ref = childData.child(childKey)
        //   let opp0Ref = bike0Ref.child(bike0Ref.key)    
       

        //   opp0Ref.observeSingleEvent(of: .value, with: { snapshot in
        //     for child in snapshot.children {
        //         let snap = child as! DataSnapshot
        //         let dict = snap.value as! [String: Any]
        //         let name = dict["name"] as! String
        //         print(name)
        //     }
        // })
    // ...  });
            });
        });
  });



  
  setData(list);
}, {
  onlyOnce: true
});

    }
    
    fetchData()
  },[])



  const handleDelete = (id) => {
    setData(data.filter((item) => item.id !== id));
  };

//   const actionColumn = [
//     {
//       field: "action",
//       headerName: "Action",
//       width: 200,
//       renderCell: (params) => {
//         // console.log(params)
//         return (
//           <div className="cellAction">
//             <Link to={`/opportunites/${params.row.id}`} style={{ textDecoration: "none" }}>
//               <div className="viewButton">View</div>
//             </Link>
//             {/* <div
//               className="deleteButton"
//               onClick={() => handleDelete(params.row.id)}
//             >
//               Delete
//             </div> */}
//           </div>
//         );
//       },
//     },
//   ];


const actionColumn = [
  {
    field: "action",
    headerName: "Action",
    width: 200,
    renderCell: (params) => {
      // console.log(params)
      return (
        <div className="cellAction">
          <Link 
              
              to={`/opportunities/${params.row.id}`}
              state= {{stateParam:params.row.sellerId, bikeKey:params.row.bikeKey}}
              
              style={{ textDecoration: "none" }} 
              //useridparent={params.row.userId}
              >
            <div className="viewButton">View</div>
          </Link>
          {/* <div
            className="deleteButton"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </div> */}
        </div>
      );
    },
  },
];


  return (
    <div className="datatable">
      <div className="datatableTitle">
        Opportunities
        <Link to="/opportunites/new" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        //columns={userColumns}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default DatatableOpportunity;