import React, { useEffect, useState }  from "react";
import Modal from 'react-modal';
import AppointmentLastCallQSODashboard from "../qsoDashboard/AppointmentLastCallQSODashboard";
import WaitingForAppointmentApprovalQSODashboard from "../qsoDashboard/WaitingForAppointmentApprovalQSODashboard"
import { useParams } from "react-router-dom";


import DoneAllIcon from '@mui/icons-material/DoneAll';
import BlockIcon from '@mui/icons-material/Block';
import { getDatabase, ref, onValue, get, update, set } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';

import { database, storeage } from "../../../config/config";
import WaitingForAppointmentApprovalWorking from "./WaitingForAppointmentApprovalWorking";

const InterestedWorking = (props) => {

    const qSOId = useParams()
    const qSOIdParent = qSOId.qso
  
    const userIdParent = props.userIdParent
    const bikeKeyParent = props.bikeKey


    const [sendFirstEmailToUserModalIsOpen, setSendFirstEmailToUserModalIsOpen] = useState(false)
    const [directAppointmentModalIsOpen, setDirectAppointmentModalIsOpen] = useState(false)
    const [waitingForAppointmentApprovalModalIsOpen, setWaitingForAppointmentApprovalModalIsOpen] = useState(false)


    const [dateFromUser, setDateFromUser] = useState("")
    const [timeFromUser, setTimeFromUser] = useState("")

    const [verifyDate, setVerifyDate] = useState(false)
    const [verifyTime, setVerifyTime] = useState(false)
    const [allChecksPositive, setAllChecksPositive] = useState(false)

    console.log("dateFromUser", dateFromUser)
    console.log("timeFromUser", timeFromUser)
    console.log("verifyDate", verifyDate)







// https://stackoverflow.com/questions/6177975/how-to-validate-date-with-format-mm-dd-yyyy-in-javascript
    useEffect(()=> {
        // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
        console.log("dateFromUser inside",dateFromUser)
            function isValidDate(dateFromUser)
            {
                // First check for the pattern
                if(!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(dateFromUser))
                    return false;
                    
                  

                // Parse the date parts to integers
                var parts = dateFromUser.split(".");
                
                var day = parseInt(parts[0], 10);
                var month = parseInt(parts[1], 10);
                var year = parseInt(parts[2], 10);

                // Check the ranges of month and year
                if(year < 1000 || year > 3000 || month == 0 || month > 12)
                    return false;
                

                var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

                // Adjust for leap years
                if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
                    monthLength[1] = 29;

                // Check the range of the day
                return day > 0 && day <= monthLength[month - 1];

      
            };
            let isDateTrue = isValidDate(dateFromUser)
            setVerifyDate(isDateTrue)
            console.log(isDateTrue)

        },[dateFromUser])

    useEffect(() => {
        function validateTime (time) {
            console.log(timeFromUser)
            const timeReg = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/
            console.log(time.match(timeReg))
            //return time.match(timeReg)
            
            if (time.match(timeReg) === null){
        
              setVerifyTime(false)
            }
            else {
              
              setVerifyTime(true)
            }
          }
          validateTime(timeFromUser)
    },[timeFromUser])

    useEffect(() => {

        if (verifyDate === true || verifyTime === true) {
            setAllChecksPositive(true)
        }

    },[])






    const openSendFirstEmailModal = () => {
        setSendFirstEmailToUserModalIsOpen(true)
      }
    

      const closeSendFirstEmailModal = () => {
        setSendFirstEmailToUserModalIsOpen(false)
      }

      const openDirectAppointmentModal = () => {
        setDirectAppointmentModalIsOpen(true)
      }

      const closeDirectAppointmentModal = () => {
        setDirectAppointmentModalIsOpen(false)
      }

      const openWaitingForAppointmentApprovalModal = () => {
        setWaitingForAppointmentApprovalModalIsOpen(true)
      }

      const closeWaitingForAppointmentApprovalModal = () => {
        setWaitingForAppointmentApprovalModalIsOpen(false)
      }



      const updateWaitingForAppointmentApproval = () => {
    
        const db = database;
    
        const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent );
            update(bikeTable, {
              qsoState:'WAITING FOR APPOINTMENT APPROVAL',
              meetingOpportunityObject:{
                  firstRequestedMeetingDate:dateFromUser, 
                  firstRequestedMeetingTime:timeFromUser,
                  bicycleNotAvailableAnymore:false,
                  // if true --> switch isFinallyAgreed to "TRUE" ; if empty still "WAITING FOR APPOINTMENT APPROVAL" ; if false "APPOINTMENT DENIED"
                  firstRequestedMeetingAgreedBySeller:"",

                  additionalMeetingOpportunityOne:{
                    dateFirstOption:"",
                    timeFirstOption:"",
                    isFirstOption:false,
                    dateSecondOption:"",
                    timeSecondOption:"",
                    isSecondOption:false,
                    dateThirdOption:"",
                    timeThirdOption:"",
                    isThirdOption:false,
                    noOption:false,
                  },

                  // if firstRequestedMeetingAgreedBySeller === false & oneOption true ==> --> switch State to "APPOINTMENT APPROVAL"
                  // if firstRequestedMeetingAgreedBySeller === false & noOption true ==> let sellerAdd Appointments again ==> 
                  // ==> mark secondRequestedMeetingAgreedByPotBuyer === false
                  secondRequestedMeetingAgreedByPotBuyer:"",
                  additionalMeetingOpportunityTwo:{
                    dateFirstOption:"",
                    timeFirstOption:"",
                    isFirstOption:false,
                    dateSecondOption:"",
                    timeSecondOption:"",
                    isSecondOption:false,
                    dateThirdOption:"",
                    timeThirdOption:"",
                    isThirdOption:false,
                    noOption:false,
                  },

                  // if secondRequestedMeetingAgreedByPotBuyer === false & oneOption true ==> --> switch State to "APPOINTMENT APPROVAL"
                    // if secondRequestedMeetingAgreedByPotBuyer === false & noOption true ==> AND UPDATE WHOLE meetingOpportunityObject with default starting Fields
                  // ==> mark STATE TO "INTERESTED"
                  thirdRequestedMeetingAgreedByPotBuyer:"",
                  finalAgreedDate:{
                    finalDate:"",
                    finalTime:"",
                    // if true and both on top are filled --> switch State to "APPOINTMENT APPROVA"L Modal --> which triggers STATE "TEST RIDING"
                    isTimeFinallyAgreed:false,
                  }
              }

            }) 
            setWaitingForAppointmentApprovalModalIsOpen(false)
      }
   

      const updateDenyAppointmentRequest = () => {
    
        const db = database;
    
        const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent );
            update(bikeTable, {
              qsoState:'APPOINTMENT LAST CALL',
            }) 
            setDirectAppointmentModalIsOpen(false)
      }



        const handleChangeDate =(e) => {
            setDateFromUser(e.target.value)
        } 

        const handleChangeTime =(e) => {
            setTimeFromUser(e.target.value)
        } 



      



    return (
          <div>
        


                {sendFirstEmailToUserModalIsOpen === true ? 


                    <div>
                        <Modal
                            isOpen={sendFirstEmailToUserModalIsOpen}
                            //onAfterOpen={afterOpenModal}
                            onRequestClose={closeSendFirstEmailModal}
                            style={{ content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                        
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            },}}
                            contentLabel="Example Modal"
                        >

                            <div style={{height:390,width :700,}}>
                                <div className="title">
                                    <h1 style={{textAlign:'center'}}>Send Email to User</h1>
                                    <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                        Send Email to User to remind it to request an appointment to the following email adress:
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        lucas.deuker@gmc.dde
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        Click here to find E-Mail Templates
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        After the Email is sent - Click on "Email Sent" so the state for this QSO will change to an intermediare state of "1. Reminder Sent"
                                    </p>

                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div>
                                        <button  onClick={closeSendFirstEmailModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                    </div>
                                    <div>
                                        <button  onClick={closeSendFirstEmailModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Email Sent</button>
                                    </div>
                                    </div>
                                </div>

                                <div>

                                

                                

                                </div>
                            </div>

                        </Modal>
                    </div>

                    :
                    <div></div>


                }








                        {directAppointmentModalIsOpen === true ? 


                        <div>
                            <Modal
                                isOpen={directAppointmentModalIsOpen}
                                //onAfterOpen={afterOpenModal}
                                onRequestClose={closeDirectAppointmentModal}
                                style={{ content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                            
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                },}}
                                contentLabel="Example Modal"
                            >

                                <div style={{height:700,width :800,}}>
                                    <div className="title">
                                        <h1 style={{textAlign:'center', paddingLeft:120, paddingRight:120}}>Send Appointment Options from Seller to User</h1>
                                        <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                            Send an Appointment Opportunity from the seller to the user. Enter a date and time in the following Format DD.MM.YYYY HH:MM(24 hour format) - e.g.: 28.02.2004 12:15
                                        </p>
                                        
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            1. Appointment Option
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            22.12.2022 15:30
                                        </p> 

                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            2. Appointment Option
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            26.12.2022 18:00
                                        </p> 


                                        <div style={{ marginTop:40, marginBottom:40, marginLeft:40}}>
                                            <AppointmentLastCallQSODashboard/>
                                        </div>




                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                         This Step will automatically switch the State to "APPOINTMENT LAST CALL" after you press "Sent to User. 
                                        </p>

                                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                        <div>
                                            <button  onClick={closeDirectAppointmentModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                        </div>
                                        <div>
                                            <button  onClick={updateDenyAppointmentRequest} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Sent to User</button>
                                        </div>
                                        </div>
                                    </div>

                                    <div>

                                    

                                    

                                    </div>
                                </div>

                            </Modal>
                        </div>

                        :
                        <div></div>


                        }



                        {waitingForAppointmentApprovalModalIsOpen === true ? 


                                <div>
                                    <Modal
                                        isOpen={waitingForAppointmentApprovalModalIsOpen}
                                        //onAfterOpen={afterOpenModal}
                                        onRequestClose={closeWaitingForAppointmentApprovalModal}
                                        style={{ content: {
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                    
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%)',
                                        },}}
                                        contentLabel="Example Modal"
                                    >

                                        <div style={{height:640,width :800,}}>
                                            <div className="title">
                                                <h1 style={{textAlign:'center', paddingLeft:120, paddingRight:120}}>Send Appointment Request to Seller</h1>
                                                <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                                    Send an Appointment Request from the user to the seller. Enter a date and time in the following Format DD.MM.YYYY HH:MM(24 hour format) - e.g.: 28.02.2004 12:15
                                                </p>
                                                
                                                <p style={{ marginLeft: 80, marginTop: 0, paddingRight:80}}>
                                                    1. Appointment Request by User
                                                </p>
                                                <div style={{display:'flex',flexDirection:'row'}}>
                                                    <div style={{display:'flex',flexDirection:'column'}}>
                                                        <p style={{marginLeft:80, fontSize:22}}>DATUM
                                                        </p>
                                                        <div style={{marginLeft:80}}>
                                                            <div style={{display:'flex', flexDirection:'row'}}>
                                                                <div>
                                                                    <label>
                                                                        <input 
                                                                            type="text" 
                                                                            placeholder="DD.MM.YYYY"
                                                                            //value="100" 
                                                                            defaultValue={dateFromUser}
                                                                            onChange={handleChangeDate} 
                                                                            style={{width:200, height:40,  font:'caption',fontSize:28, fontWeight:'900', font:'caption'}}
                                                                        />
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    { verifyDate === false ?
                                                                    <div>
                                                                    <BlockIcon style={{fontSize:29, color:'red', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                   
                                                                    :
                                                                    <div>
                                                                    <DoneAllIcon style={{fontSize:29, color:'green', marginTop:8, marginLeft:10}}/>
                                                                </div>
                                                                    
                                                                    }
                                                                </div>
                                                             
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{display:'flex',flexDirection:'column', marginLeft:140}}>
                                                        <p style={{fontSize:22}}>
                                                            UHRZEIT
                                                        </p>
                                                        <div style={{display:'flex', flexDirection:'row'}}>
                                                                <div>
                                                                    <label>
                                                                        <input 
                                                                            type="text" 
                                                                            placeholder="HH:MM"
                                                                            //value="100" 
                                                                            onChange={handleChangeTime} 
                                                                            defaultValue={timeFromUser}
                                                                            style={{width:160, height:40,  font:'caption', fontSize:28, fontWeight:'900',}}
                                                                        />
                                                                    </label>
                                                                </div>

                                                                <div>
                                                                    { verifyTime === false ?
                                                                    <div>
                                                                    <BlockIcon style={{fontSize:29, color:'red', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                   
                                                                    :
                                                                    <div>
                                                                    <DoneAllIcon style={{fontSize:29, color:'green', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                    }
                                                                </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                    {/* <div>
                                                        <label>
                                                            <input type="text" value={favoriteInput} onChange={handleChange} style={{width:290}}/>
                                                        </label>
                                                    </div>
                                                
                                                <div style={{marginLeft: 40}}>
                                                    <input type="submit" value="FAHRRAD VORSCHLAGEN !" style={{paddingLeft: 30, paddingRight:30}}/>    
                                                </div> */}

                                                {/* <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    2. Appointment Option
                                                </p>
                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                    26.12.2022 18:00
                                                </p>  */}


                                                <div style={{ marginTop:40, marginBottom:40, marginLeft:40}}>
                                                    <WaitingForAppointmentApprovalQSODashboard />
                                                </div>




                                                <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                                This Step will automatically switch the State to "APPOINTMENT LAST CALL" after you press "Sent to User. 
                                                </p>

                                                <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                                <div>
                                                    <button  onClick={closeWaitingForAppointmentApprovalModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                                </div>
                                               
                                               {/* { allChecksPositive === true ? */}
                                                <div>
                                                    <button  onClick={updateWaitingForAppointmentApproval} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Sent to Seller</button>
                                                </div>
                                                    {/* :
                                                <div>
                                                    <button style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400, opacity:0.5 }}>Sent to Seller</button>
                                                </div>
                                               } */}
                                                </div>
                                            </div>

                                            <div>

                                            

                                            

                                            </div>
                                        </div>

                                    </Modal>
                                </div>

                                :
                                <div></div>


                        }












                 
                <div>
                


                <div className="search">
                
        

                <div style={{display: 'flex', flexDirection:'row', marginBottom: 10,}}>

                <div>
                    <div style={{marginRight:40, marginLeft:10}}>
                    
                    </div>
                </div>
                
                    <div>
                    <div style={{marginTop:0, marginBottom:13}}>
                    <div style={{fontSize:22, fontWeight:'1000'}}>
                    INTERESTED
                    </div>
                    <div style={{marginTop:10, marginBottom: 8}}>
                        REMIND THE USER TO ADD AN APPOINTMENT REQUEST
                    </div>


                    <button 
                        onClick={openSendFirstEmailModal}
                        style={{width: 340, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#e69b00', border:'1px solid black', borderRadius: 20, marginBottom: 15, marginTop:12}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'black',}}>
                        SEND EMAIL
                        </div>
                    </div>
                    </button>

                    <div style={{width: 340, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#e69b00', border:'1px solid black', borderRadius: 20, marginBottom: 15,}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 7}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'black',}}>
                        SEND PUSH NOTIFICATION
                        </div>
                    </div>
                    </div>




                    <div style={{marginTop:10, marginBottom: 8}}>
                        SEND FIRST APPOINTMENT REQUEST FROM USER TO SELLER BASED
                    </div>


                    <button 
                         onClick={openWaitingForAppointmentApprovalModal}
                        style={{width: 340, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15,}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                        SEND APPOINTMENT REQUEST TO SELLER
                        </div>
                    </div>
                    </button>




                    <div style={{marginTop:10, marginBottom: 8}}>
                        SEND APPOINTMENT TO USER BASED ON SELLERS AVAILABLE MEETING TIME
                    </div>


                    <button 
                         onClick={openDirectAppointmentModal}
                        style={{width: 340, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'white', border:'1px solid #1870b5', borderRadius: 20, marginBottom: 15,}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'#1870b5',}}>
                        SEND APPOINTMENT TO USER
                        </div>
                    </div>
                    </button>

                
                    
                    
                    </div>

                
                </div>
                </div>  











                </div>
                </div>

  
  
          </div>
    )
  }
  
  export default InterestedWorking