// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword} from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "admin-cycle.firebaseapp.com",
  projectId: "admin-cycle",
  storageBucket: "admin-cycle.appspot.com",
  messagingSenderId: "1063891859190",
  appId: "1:1063891859190:web:02abfff537f67b45595ed7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth()