import { useEffect, useState } from "react";
import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import { getDatabase, ref, onValue, get } from "firebase/database";
import { database } from "../../config/config";


const Widget = ({ type }) => {
  let data;

  const [amountUsers, setAmountUsers] = useState(0)
  const [amountDealers, setAmountDealers] = useState(0)
  const [amountOpportunities, setAmountOpportunities] = useState(0)
  const [amountBikes, setAmountBikes] = useState(0)
  const [amountQSOs, setAmountQSOs] = useState(0)
  const [amountMatches, setAmountMatches] = useState(0)
  const [amountCycleRides, setAmountCycleRides] = useState(0)
  const [amountDeals, setAmountDeals] = useState(0)
  const [amountRides, setAmountRides] = useState(0)

  console.log("amountMatches", amountMatches)


useEffect(() => {
    const fetchData = async () => {

      let listMatches = []
      let listUsers = []
      let listBikes = []
      let listOpportunities = []
      let listQSOs = []
      let listDealers = []
      let listRides = []

      const db = database;
      const dbRef = ref(db, 'users/');
      const dbRefMatches = ref(db, 'matches/');
      const dbRefBikes = ref(db, 'bikes/');
      const dbRefQSOs = ref(db, 'qualifiedSalesOpportunity/');
      const dbRefOpportunities = ref(db, 'exportMarketplace/');
      const dbRefPartners = ref(db, 'partnerDatabase/');
      const dbRefRides = ref(db, 'cycleRides/');


    //   db.ref('/bikes/}').onWrite((change, context) => {
    //     const post_id = context.params.post_id;
    //     const commentsCount = change.after.numChildren();
    // })


      // get(ref(db,"users/" )).then((snapshot)=>{
      //   // snapshot.forEach((childSnapshot) => {
      //     const childKey = snapshot.key;
          
      //     const childCount = snapshot.getChildrenCount()
      //     const childData = snapshot.val()
      //     amountBikes = amountBikes + 1

      //     console.log("childCount", childCount)
                
      //   // })
            
      // })

        // const uploadTask = uploadBytesResumable(storageRef, file).then((snapshot) => {
        //   getDownloadURL(snapshot.ref)
        //   .then((url) => {
        //     setImageUrls((prev) => [...prev, url]);
        //   });
        // });

      // let nywTrx = database.ref("matches/");
      // onValue(db, "matches/" (snapshot) => {
      //   nywTrx.once("value")
      //     .then(function(snapshot) {
      //       var a = snapshot.numChildren(); // 1 ("name")
      //       var b = snapshot.child("name").numChildren(); // 2 ("first", "last")
      //       console.log("test",a)
      //       var c = snapshot.child("name/first").numChildren(); // 0
      //     });
      //   });

      //     // exports.children = db
          //   .ref('/matches')
          //   .onWrite((change, context) => {
          //     console.log(change.after.numChildren());
          //     return null;
          //   });
      // var ref = db.ref("matches");
      //   ref.once("value")
      //     .then(function(snapshot) {
      //       console.log(snapshot.numChildren()); 
      // });

      onValue(dbRef, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            listUsers.push(childKey)
            });
          });
        setAmountUsers(listUsers.length);




      onValue(dbRefMatches, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            childSnapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            listMatches.push(childKey)

            });
          });
          setAmountMatches(listMatches.length);
      })
      onValue(dbRefBikes, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            childSnapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            listBikes.push(childKey)

            });
          });
          setAmountBikes(listBikes.length);
      })

      onValue(dbRefOpportunities, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            childSnapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            listOpportunities.push(childKey)

            });
          });
          setAmountOpportunities(listOpportunities.length);
      })

      onValue(dbRefQSOs, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            childSnapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            listQSOs.push(childKey)

            });
          });
          setAmountQSOs(listQSOs.length);
      })    
      onValue(dbRefPartners, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            childSnapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            listDealers.push(childKey)
            });
          });
          setAmountDealers(listDealers.length);
      })


      onValue(dbRefRides, (snapshot) => {
         snapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val()
            console.log("childKey", childKey)
            listRides.push(childKey)
            });
          });
          setAmountRides(listRides.length);
      
    }    
    fetchData()
  },[])








  switch (type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: "See all users",
        counter: amountUsers,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "bikes":
      data = {
        title: "BIKES",
        isMoney: false,
        link: "View all bikes",
        counter: amountBikes,
        icon: (
          <PedalBikeIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "dealers":
      data = {
        title: "DEALERS",
        isMoney: false,
        link: "View all earnings",
        counter: amountDealers,
        icon: (
          <MonetizationOnOutlinedIcon
          className="icon"
          style={{
            color: "crimson",
            backgroundColor: "rgba(255, 0, 0, 0.2)",
          }}
          />
        ),
      };
      break;
    case "opportunities":
      data = {
        title: "OPPORTUNITIES",
        isMoney: false,
        link: "See opportunities",
        counter: amountOpportunities,
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
      case "matches":
      data = {
        title: "MATCHES",
        isMoney: false,
        link: "See all Search Requests",
        counter: amountMatches,
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
      case "qualifiedSalesOpportunities":
      data = {
        title: "QSO COUNT",
        isMoney: false,
        link: "See QSOs",
        counter: amountQSOs,
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
      case "orders":
      data = {
        title: "ORDERS",
        isMoney: false,
        link: "See details",
        counter: amountDeals,
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
      case "cycleRides":
      data = {
        title: "CYCLE RIDES",
        isMoney: false,
        link: "",
        counter: amountRides,
        icon: (
          <PedalBikeIcon
          className="icon"
          style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {data.counter}
        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        {/* <div className="percentage positive">
          <KeyboardArrowUpIcon />
          {diff} %
        </div> */}
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;