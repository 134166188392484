import "./userbikelist.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";

import VisibilityIcon from '@mui/icons-material/Visibility';


import { getDatabase, ref, onValue, get, update } from "firebase/database";
import { database } from "../../config/config";

const UserBikeList = ({userId}) => {
  
    // console.log("USERID HELLEOL HELLO",userId)


    const [userBikesData, setUserBikesData] = useState([]);
    console.log("!!!???USERBIKEDATA",userBikesData);
    // console.log("!!!???USERBIKEDATA",userBikesData.bikeKey);
    

//     useEffect(() => {

//         const db = database;
//         get(ref(db,"bikes/" + userId + "/" )).then((snapshot)=>{
//             setUserBikesData(snapshot)
            
    
//     })
// })

useEffect(() => {
    const fetchData = async () => {

      let list = []

      const db = database;
      const dbRef = ref(db, 'bikes/' + userId);

      onValue(dbRef, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
          list.push(childData)
    // ...
  });
  setUserBikesData(list);
}, {
  onlyOnce: true
});

    }
    
    fetchData()
  },[])

    
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

    const rows = [
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
    {
      id: 2235235,
      product: "Playstation 5",
      img: "https://m.media-amazon.com/images/I/31JaiPXYI8L._AC_UY327_FMwebp_QL65_.jpg",
      customer: "Michael Doe",
      date: "1 March",
      amount: 900,
      method: "Online Payment",
      status: "Pending",
    },
    {
      id: 2342353,
      product: "Redragon S101",
      img: "https://m.media-amazon.com/images/I/71kr3WAj1FL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 35,
      method: "Cash on Delivery",
      status: "Pending",
    },
    {
      id: 2357741,
      product: "Razer Blade 15",
      img: "https://m.media-amazon.com/images/I/71wF7YDIQkL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "Jane Smith",
      date: "1 March",
      amount: 920,
      method: "Online",
      status: "Approved",
    },
    {
      id: 2342355,
      product: "ASUS ROG Strix",
      img: "https://m.media-amazon.com/images/I/81hH5vK-MCL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "Harold Carol",
      date: "1 March",
      amount: 2000,
      method: "Online",
      status: "Pending",
    },
  ];
  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">BICYCLE ID</TableCell>
            <TableCell className="tableCell">Picture Upload Link</TableCell>
            {/* <TableCell className="tableCell">Customer</TableCell> */}
            {/* <TableCell className="tableCell">Date</TableCell>
            <TableCell className="tableCell">Amount</TableCell>
            <TableCell className="tableCell">Payment Method</TableCell> */}
            {/* <TableCell className="tableCell">Picture Upload Link</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {userBikesData.map((row) => (
            <TableRow key={row.bikeKey}>
              <TableCell className="tableCell">
                {row.bikeKey}
                </TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  {/* <img src={row.img} alt="" className="image" /> */}
                  <div style={{marginLeft: 10}} className="itemAction">
                    <button 
                        style={{display: 'flex', height: 30, width: 30, borderRadius:15, alignItems:'center', justifyContent:'center' }} className="actionButton"
                        onClick={() => openInNewTab(row.bikePictureUser)}
                        >
                      <VisibilityIcon style={{fontSize: 20, alignSelf:'center'}}/>
                    </button>
                  </div>
               

                </div>
              </TableCell>
              {/* <TableCell className="tableCell">{row.customer}</TableCell> */}
              {/* <TableCell className="tableCell">{row.date}</TableCell>
              <TableCell className="tableCell">{row.amount}</TableCell>
              <TableCell className="tableCell">{row.method}</TableCell> */}
              {/* <TableCell className="tableCell">
                <span className={`status ${row.status}`}>{row.status}</span>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserBikeList;
