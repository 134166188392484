import "./navbar.scss";
import SearchIcon from '@mui/icons-material/Search';
import LanguageIcon from '@mui/icons-material/Language';
import { ChatBubbleOutlineOutlined, DarkModeOutlined, FullscreenExitOutlined, ListOutlined, NotificationsNoneOutlined } from "@mui/icons-material";

const Navbar = () => {
  return (
    <div className="navbar">
        <div className="wrapper">
          <div>
            {/* <input type="text" placeholder="Search..."/> */}
            {/* <SearchIcon /> */}
          </div>
          <div className="items">
            <div className="item">
              <LanguageIcon />
              English
            </div>
            <div className="item">
              <DarkModeOutlined />
            </div>
            {/* <div className="item">
              <FullscreenExitOutlined />
            </div>
            <div className="item">
              <NotificationsNoneOutlined />
              <div className="counter">1</div>
            </div>
            <div className="item">
              <ChatBubbleOutlineOutlined />
              <div className="counter">2</div>
            </div> */}
            {/* <div className="item">
              <ListOutlined />
            </div> */}
            <div className="item">
              <img 
                src="https://pro2-bar-s3-cdn-cf6.myportfolio.com/17be4dd08c5417027a544816a909fcf8/01b4cc12-6d30-46e1-b8d0-ab3ca15d0fad_rw_1200.png?h=078dbf9c704d63a54cb6942823239e32"
                alt=""
                className="avatar"
              />
            </div>
          </div>
        </div>
    </div>
  )
}

export default Navbar