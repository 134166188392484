import React from "react";

import "./singlePartners.scss";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useParams, useLocation } from "react-router-dom";

import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import VerifiedIcon from '@mui/icons-material/Verified';
import PaletteIcon from '@mui/icons-material/Palette';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import { HexColorPicker } from "react-colorful";
import Modal from 'react-modal';

import * as ImageHelpers from "../../helpers/imageHelpers"
import { ref as RefStor, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase, ref, onValue, get, update } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';


import { database, storeage } from "../../config/config";
import UserBikeList from "../../components/userbikelist/UserBikeList";
import { UploadFileSharp } from "@mui/icons-material";
//import { height } from "@mui/system";



// check firebase rules if backend changes work with write access


const SinglePartners = (props) => {

  const id = useParams()
  //const userId = id.userID
  const userId = props.userId

  const stateParam = useLocation().state;
  // HOW TO GET STATE FROM PARENT COMPONENT https://ui.dev/react-router-pass-props-to-link
  const location = useLocation()
  const { from } = location.state
  const userIdParent = location.state.userId

  const [partnerData, setPartnerData] = useState("")
  const [partnerState, setPartnerState] = useState("")
  const [bikePictureVerified, setBikePictureVerified] = useState("")
  const [partnerStreet, setPartnerStreet] = useState("")
  const [highlightColor, setHighlightColor] = useState("")
  const [partnerNumber, setPartnerNumber] = useState("")
  const [partnerZipCode, setPartnerZipCode] = useState("")
  const [partnerTown, setPartnerTown] = useState("")


  console.log("user Id in partners", userIdParent)

 
  //const newEmail = userData
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)


  
  


  useEffect(() => {
    const fetchData = async () => {

      let list = []

      const db = database;
      const dbRef = ref(db, 'partnerDatabase/' + userIdParent);

      onValue(dbRef, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const key = childSnapshot.key;
          const data = childSnapshot.val();
          setPartnerData(data);
          console.log("partner data",data)
  });
  
}, {
  onlyOnce: true
});
    }

   
    
    fetchData()
  },[])











  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div style={{width: '100%',
        fontSize: 24,
        color: 'gray',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', margin:20}}>
        Partner
        </div>
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">Information</h1>
            <div className="item">
              <img
                src="https://pro2-bar-s3-cdn-cf6.myportfolio.com/17be4dd08c5417027a544816a909fcf8/01b4cc12-6d30-46e1-b8d0-ab3ca15d0fad_rw_1200.png?h=078dbf9c704d63a54cb6942823239e32"
                alt=""
                className="itemImg"
              />
              <div className="details">
                <h1 className="itemTitle"></h1>
                <div className="detailItem">
                  <span className="itemKey">Name:</span>
                  <span className="itemValue">{partnerData.dealerName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Partner Id:</span>
                  <span className="itemValue">{partnerData.dealerKey}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone Number:</span>
                  <span className="itemValue">{partnerData.dealerPhoneNumber}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">
                  {partnerData.dealerStreet} {partnerData.dealerHouseNumber} - {partnerData.dealerZipCode} {partnerData.dealerCity}
                  </span>
                </div>
                {/* <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">-</span>
                </div> */}
              </div>
            </div>
          </div>
         
         
          <div className="right">
          <h1 className="title">PARTNER INFORMATIONEN</h1>
          <div className="details">

                <div className="detailItem" style={{display: 'flex', flexDirection:'row', marginBottom: 10}}>
                  <div>
                    <span className="itemKey">Adjust Opening Hours</span>
                  </div>
                  <div>
                    <span style={{marginLeft: 10}} className="itemValue"></span>
                  </div>
                  <div>
                    <span className="itemAction"></span>
                  </div>
                </div>
                
                <div className="detailItem" style={{display: 'flex', flexDirection:'row', marginBottom: 10}}>
                  <div>
                    <span className="itemKey">Add Favorite Selling Hours</span>
                  </div>
                  <div>
                    <span style={{marginLeft: 10}} className="itemValue"></span>
                  </div>
   
                </div>


                <div className="detailItem" style={{display: 'flex', flexDirection:'row', marginBottom: 10}}>
                  <div>
                    <span className="itemKey">Adjust Pick Up Adress</span>
                  </div>
                  <div>
                    <span style={{marginLeft: 10}} className="itemValue"></span>
                  </div>
   
                </div>

      

   
             
                
                
               
               
          </div>
          
          </div>
        </div>
        <div className="bottom">
        <h1 className="title">Bicycles</h1>
          <UserBikeList userId={userId}/>
        </div>
      </div>
    </div>
  );
};

export default SinglePartners;