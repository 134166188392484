export const userColumns = [
    { field: "id", headerName: "ID", width: 200,
   
   
    renderCell: (params) => {
        return (
          <div>
            {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
            <div>
               {params.row.id}
             </div>
          </div>
        );
      },
    },

    {
        field: "userId",
        headerName: "User",
        width: 280,
        // renderCell: (params) => {
        //     return (
        //       <div>
        //         {params}
        //       </div>
        //     );
        //   },
        
      //   renderCell: (params) => {
      //     return (
      //       <div className="cellWithImg">
      //         {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
      //         {/* <img className="cellImg" src={params.row.highlightPictureHeight} alt="" /> */}
      //       </div>
      //     );
      //   },
      },


    {
      field: "matchStartingDate",
      headerName: "Starting Date",
      width: 190,
      renderCell: (params) => {
        return (
          <div>
            {params.row.matchStartingDate}
          </div>
        );
      },
    //   renderCell: (params) => {
    //     return (
    //       <div className="cellWithImg">
    //         {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
    //         {/* <img className="cellImg" src={params.row.highlightPictureHeight} alt="" /> */}
    //       </div>
    //     );
    //   },
    },
    {
      field: "matchPrice",
      headerName: "Match Max Price",
      width: 150,
      renderCell: (params) => {
              return (
                <div>
                  {params.row.matchPrice}
                </div>
              );
            },
    },
  
    // {
    //   field: "age",
    //   headerName: "Age",
    //   width: 100,
    // },
    // {
    //   field: "available",
    //   headerName: "Available?",
    //   width: 120,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {params.row.available}
    //       </div>
    //     );
    //   },
    // },
    // {
    //     field: "pickUpZipCode",
    //     headerName: "Zip Code",
    //     width: 120,
    //     renderCell: (params) => {
    //       return (
    //         <div>
    //           {params.row.userPickupLocation.pickUpZipCode}
    //         </div>
    //       );
    //     },
    //   },
  ];