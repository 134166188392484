import "./listPartners.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
//import DatatableOpportunities from "../../components/datatable/DatatableOpportunities"
import DatatablePartners from "../../components/datatablePartners/DatatablePartners"

const ListPartners = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        {/* <DatatableOpportunities/> */}
        <DatatablePartners />
      </div>
    </div>
  )
}

export default ListPartners