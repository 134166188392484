// import "./listQualifiedSalesOpportunities.scss"
// import Sidebar from "../../components/sidebar/Sidebar"
// import Navbar from "../../components/navbar/Navbar"
//import DatatableOpportunities from "../../components/datatable/DatatableOpportunities"
//import DatatableOpportunity from "../../components/datatableOpportunity/DatatableOpportunity"
// import DatatableQualifiedSalesOpportunites from "../../components/datatableQualifiedSalesOpportunities/DatatableQualifiedSalesOpportunites"

const AppointmentApprovalQSODashboard = () => {
  return (
        <div>
      
                <div style={{display:'flex',flexDirection:'row'}}>
                    <div style={{ width:120,}}>
                        <div style={{ justifyContent:'center', alignItems:'center',flexDirection:'row', }}>
                            <div style={{marginLeft:15, backgroundColor:'#00224b', width:50,height:50, borderRadius:35, border: '2px solid black', borderWidth:2, marginBottom:10}}>

                            </div>
                            <div>
                                INTERESTED
                            </div>
                        </div>
                    </div>
                    
                    
                    <div style={{ width:120,}}>
                        <div style={{ justifyContent:'center', alignItems:'center',flexDirection:'row', }}>
                            <div style={{marginLeft:35, backgroundColor:'#00224b', width:50,height:50, borderRadius:35, border: '2px solid black', marginBottom:10}}>

                            </div>
                            <div style={{fontSize:10, textAlign:'center', fontWeight:'700'}}>
                            WAITING FOR APPOINTMENT APPROVAL
                            </div>
                        </div>
                    </div>

                    <div style={{ width:90,}}>
                        <div style={{ justifyContent:'center', alignItems:'center',flexDirection:'row', }}>
                            <div style={{marginLeft:30, backgroundColor:'#00224b', width:30,height:30, borderRadius:25, border: '2px solid black', marginBottom:20,marginTop:10}}>

                            </div>
                            <div style={{fontSize:10, textAlign:'center', fontWeight:'700'}}>
                                APPOINTMENT DENIED
                            </div>
                        </div>
                    </div>

                    <div style={{ width:90,}}>
                        <div style={{ justifyContent:'center', alignItems:'center',flexDirection:'row', }}>
                            <div style={{marginLeft:30, backgroundColor:'#00224b', width:30,height:30, borderRadius:25, border: '2px solid black', marginBottom:20,marginTop:10}}>

                            </div>
                            <div style={{fontSize:10, textAlign:'center', fontWeight:'700',}}>
                                WAITING FOR LAST CALL
                            </div>
                        </div>
                    </div>

                    <div style={{ width:90,}}>
                        <div style={{ justifyContent:'center', alignItems:'center',flexDirection:'row', }}>
                            <div style={{marginLeft:30, backgroundColor:'#00224b', width:30,height:30, borderRadius:25, border: '2px solid black', marginBottom:20,marginTop:10}}>

                            </div>
                            <div style={{fontSize:10, textAlign:'center', fontWeight:'700',}}>
                            APPOINTMENT LAST CALL
                            </div>
                        </div>
                    </div>


                    <div style={{ width:90,}}>
                        <div style={{ justifyContent:'center', alignItems:'center',flexDirection:'row', }}>
                            <div style={{marginLeft:30, backgroundColor:'#6fc276', width:30,height:30, borderRadius:25, border: '2px solid black', marginBottom:20,marginTop:10}}>

                            </div>
                            <div style={{fontSize:10, textAlign:'center', fontWeight:'700'}}>
                                APPOINTMENT APPROVAL
                            </div>
                        </div>
                    </div>

                    <div style={{ width:120,}}>
                        <div style={{ justifyContent:'center', alignItems:'center',flexDirection:'row', }}>
                            <div style={{marginLeft:35, backgroundColor:'lightgrey', width:50,height:50, borderRadius:35, border: '2px solid black',  marginBottom:10}}>

                            </div>
                            <div style={{textAlign:'center',}}>
                            TEST RIDING
                            </div>
                        </div>
                    </div>

                </div>



        </div>
  )
}

export default AppointmentApprovalQSODashboard