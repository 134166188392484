import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import ListOpportunities from "./pages/opportunities/ListOpportunities";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import ListSearchRequests from "./pages/searchRequests/ListSearchRequests";
import SingleSearchRequest from "./pages/singleSearchRequest/SingleSearchRequest";
import ListQualifiedSalesOpportunities from "./pages/qualifiedSalesOpportunities/ListQualifiedSalesOpportunities";
import ListBikes from "./pages/bikes/ListBikes";
import SingleQSOs from "./pages/singleQSOs/SingleQSOs";
import SingleOpportunities from "./pages/singleOpportunities/SingleOpportunities";
import SingleBikes from "./pages/singleBikes/SingleBikes";
import ListDeals from "./pages/deals/ListDeals";
import SingleDeals from "./pages/singleDeals/SingleDeals";
import ListPartners from "./pages/partners/ListPartners";
import SinglePartners from "./pages/singlePartners/SinglePartners";

function App() {


  // turn false before bringing app live

  const {currentUser} = useContext(AuthContext)


  //const currentUser = false; 

  const RequireAuth = ({children}) => {
    return currentUser ? (children) : 
    <Navigate to="/login"/> 
  };

  console.log(currentUser)

  return (
    <div className="App">
      <BrowserRouter>
    <Routes>
      <Route path="/" >
        <Route path="login" element={<Login/>}/>
        <Route index element={<RequireAuth><Home/></RequireAuth>}/>
        
        <Route path="users">
          <Route index element={<RequireAuth><List/></RequireAuth>}/>
          <Route path=":userID" element={<RequireAuth><Single/></RequireAuth>}/>
          <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add New User"/></RequireAuth>}/>
        </Route>
        <Route path="partners">
          <Route index element={<RequireAuth><ListPartners/></RequireAuth>}/>
          <Route path=":partnerID" element={<RequireAuth><SinglePartners/></RequireAuth>}/>
          {/* <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add New User"/></RequireAuth>}/> */}
        </Route>
        <Route path="bikes">
          <Route index element={<RequireAuth><ListBikes/></RequireAuth>}/>
          <Route path=":bikes" element={<RequireAuth><SingleBikes/></RequireAuth>}/>
          {/* <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add New User"/></RequireAuth>}/> */}
        </Route>
        <Route path="opportunities">
          <Route index element={<RequireAuth><ListOpportunities/></RequireAuth>}/>
          <Route path=":opportunity" element={<RequireAuth><SingleOpportunities/></RequireAuth>}/>
          {/* <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add New Opportunity"/></RequireAuth>}/> */}
        </Route>
        <Route path="match">
          <Route index element={<RequireAuth><ListSearchRequests/></RequireAuth>}/>
          <Route path=":match" element={<RequireAuth><SingleSearchRequest/></RequireAuth>}/>
          {/* <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add New Opportunity"/></RequireAuth>}/> */}
        </Route>
        <Route path="qualifiedSalesOpportunities">
          <Route index element={<RequireAuth><ListQualifiedSalesOpportunities/></RequireAuth>}/>
          <Route path=":qso" element={<RequireAuth><SingleQSOs/></RequireAuth>}/>
          {/* <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add New Opportunity"/></RequireAuth>}/> */}
        </Route>
        <Route path="deals">
          <Route index element={<RequireAuth><ListDeals/></RequireAuth>}/>
          <Route path=":deals" element={<RequireAuth><SingleDeals/></RequireAuth>}/>
          {/* <Route path="new" element={<RequireAuth><New inputs={userInputs} title="Add New Opportunity"/></RequireAuth>}/> */}
        </Route>
        {/* <Route path="products">
          <Route index element={<RequireAuth><List/></RequireAuth>}/>
          <Route path=":productId" element={<RequireAuth><Single/></RequireAuth>}/>
          <Route path="new" element={<RequireAuth><New/></RequireAuth>}/>
        </Route> */}
      </Route>
    </Routes>
  </BrowserRouter>
    </div>
  );
}

export default App;
