// TO DO
// IMPORT Componentents for different States into this Component


import React from "react";

import "./singleQSOs.scss";
import { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { useParams, useLocation } from "react-router-dom";

import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import SellIcon from '@mui/icons-material/Sell';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { HexColorPicker } from "react-colorful";
import Modal from 'react-modal';

import * as ImageHelpers from "../../helpers/imageHelpers"
import { ref as RefStor, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase, ref, onValue, get, update } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';


import { database, storeage } from "../../config/config";
import UserBikeList from "../../components/userbikelist/UserBikeList";
import { UploadFileSharp } from "@mui/icons-material";
import InterestedQSODashboard from "./qsoDashboard/InterestedQSODashboard";
import AppointmentApprovalQSODashboard from "./qsoDashboard/AppointmentApprovalQSODashboard";
import AppointmentDeniedQSODashboard from "./qsoDashboard/AppointmentDeniedQSODashboard";
import TestRidingQSODashboard from "./qsoDashboard/TestRidingQSODashboard";
import AppointmentLastCallQSODashboard from "./qsoDashboard/AppointmentLastCallQSODashboard";
import WaitingForLastCallQSODashboard from "./qsoDashboard/WaitingForLastCallQSODashboard";
import WaitingForAppointmentApprovalQSODashboard from "./qsoDashboard/WaitingForAppointmentApprovalQSODashboard";
import InterestedWorking from "./workingDashboard/InterestedWorking";
import WaitingForAppointmentApprovalWorking from "./workingDashboard/WaitingForAppointmentApprovalWorking";
import AppointmentDeniedWorking from "./workingDashboard/AppointmentDeniedWorking";
import WaitingForLastCallWorking from "./workingDashboard/WaitingForLastCallWorking";
import TestRidingWorking from "./workingDashboard/TestRidingWorking";
import AppointmentApprovalWorking from "./workingDashboard/AppointmentApprovalWorking";
import AppointmentLastCallWorking from "./workingDashboard/AppointmentLastCallWorking";
import WaitingForDealVerificationWorking from "./workingDashboard/WaitingForDealVerificationWorking";
//import { height } from "@mui/system";






// check firebase rules if backend changes work with write access


const SingleQSOs = ({state}) => {

  const id = useParams()
  const userId = id.userID

  const stateParam = useLocation().state;
  // HOW TO GET STATE FROM PARENT COMPONENT https://ui.dev/react-router-pass-props-to-link
  const location = useLocation()
  const { from } = location.state
  const userIdParent = location.state.stateParam

  const [qSOData, setQSOData] = useState("")
  const [bikeUserData, setBikeUserData] = useState("")
  const [bikeKey, setBikeKey] = useState("")
//   const [firstBikeAdded, setFirstBikeAdded] = useState("❌")
//   const [bikePictureAdded, setBikePictureAdded] = useState("❌")
//   const [bikePictureVerified, setBikePictureVerified] = useState("❌")
//   const [highlightPictureCreated, setHighlightPictureAdded] = useState("❌")
//   const [appBackgroundCreated, setAppBackgroundCreated] = useState("❌")
//   const [highlightColorAdded, setHighlightColorAdded] = useState("❌")

//   const [bikePictureUserLink, setBikePictureUserLink] = useState("")
//   const [highlightPictureLink, setHighlightPictureLink] = useState("")
//   const [appBackgroundLink, setAppBackgroundLink] = useState("")
//   const [highlightColor, setHighlightColor] = useState("")

//   const [newColor, setNewColor] = useState(highlightColor)


//   const [openColorPicker, setOpenColorPicker] = useState(false);
//   const [openUploadAppBackgroundModal, setOpenUploadAppBackgroundModal] = useState(false);
//   const [openUploadHighlightPictureModal, setOpenUploadHighlightPictureModal] = useState(false);
  
//   const [appBackgroundImage, setAppBackgroundImage] = useState();
//   const [backgroundProgress, setBackgroundProgress] = useState(0);

//   const [modalIsOpen, setIsOpen] = React.useState(false);
//   const [highlightModalIsOpen, setHighlightModalIsOpen] = React.useState(false);
//   const [imageUrls, setImageUrls] = useState([]);

//   const [dragActive, setDragActive] = React.useState(false);
//   const inputRef = React.useRef(null);


  
  console.log("SINGLE COMPONENT STARTS HERE")

  console.log("USER ID IN SINGLE QSO", userIdParent)
  console.log("USER DATA",qSOData)




  //const newEmail = userData
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  
  


  useEffect(() => {
    const fetchData = async () => {


      console.log("QSO ID in useEffect?", id.qso)
      let list = []

      const db = database;
      const dbRef = ref(db, 'qualifiedSalesOpportunity/' + userIdParent);

      onValue(dbRef, (snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const key = childSnapshot.key;
          const data = childSnapshot.val();
          console.log("data of all QSOs per User", data)
            // for (var i = 0; i < data.length; i++) {
                if (id.qso === data.id) {
                    setQSOData(data);
                    setBikeKey(data.bikeKey)
                    console.log("QSO SINGLE",data)
                }
            // }
            // setUserData(data);
            // console.log("QSO SINGLE",data)



        
        //highlightColor

          // list.push(childData)
    // ...

   

  });
  
}, {
  onlyOnce: true
});




    }

   
    
    fetchData()
  },[])


//   const openInNewTab = url => {
//     window.open(url, '_blank', 'noopener,noreferrer');
//   };

//   const openReactColorPicker = () => {
//     setOpenColorPicker(true)
//   }

//   const closeReactColorPicker = () => {
//     setOpenColorPicker(false)
//   }

//   const updateHighlightColor = () => {
//     const db = database;
   
//     update(ref(db,"usersInformation/"+ userId + "/" ), {
//       highlightColor:newColor, 
      

//     })
//     .catch((error)=>{
//         alert("unsuccessfull, error" + error);
//     })
//     setOpenColorPicker(false)

//   }

//MODAL
  // handle drag events
// const handleDrag = function(e) {
//   e.preventDefault();
//   e.stopPropagation();
//   if (e.type === "dragenter" || e.type === "dragover") {
//     setDragActive(true);
//   } else if (e.type === "dragleave") {
//     setDragActive(false);
//   }
// };

// // triggers when file is dropped
// const handleDrop = function(e) {
//   e.preventDefault();
//   e.stopPropagation();
//   setDragActive(false);
//   if (e.dataTransfer.files && e.dataTransfer.files[0]) {
//     // handleFiles(e.dataTransfer.files);
//   }
// };

// // triggers when file is selected with click
// const handleChange = function(e) {
//   e.preventDefault();
//   if (e.target.files && e.target.files[0]) {
//     // handleFiles(e.target.files);
//     setAppBackgroundImage(e.target.files[0])
//   }
// };

// const onFileChange = async (appBackgroundImage) => {

//   //const storeNew = storeage

//   const userId = id.userID
//   console.log("21212",userId)
//   const newStorage = storeage
//   const file = appBackgroundImage
//   const storageRef = newStorage.RefStor()
//   const fileRef = storageRef.child(file.name)

//   await fileRef.put(file)
//   const fileUrl = await storageRef.getDownloadURL()

  
// }


///https://www.youtube.com/watch?v=pJ8LykeBDo4
// const formHandler = (e) => {
//   e.preventDefault()
//   const file = e.target[0].files[0]
//   //uploadFile(file)
// }




// const uploadFile = async (file) => {
//   const userId = id.userID
//   if(!file) return;
//   const storageRef = RefStor(storeage, 'usersInformation/' + userId + "/" + userId)
//   //const uploadTask = uploadBytesResumable(storageRef, file);

  

//   const uploadTask = uploadBytesResumable(storageRef, file).then((snapshot) => {
//     getDownloadURL(snapshot.ref)
//     .then((url) => {
//       setImageUrls((prev) => [...prev, url]);
//     });
//   });


//   console.log(uploadTask)

//   uploadTask.on("state_changed", (snapshot) => {
//     const prog = Math.round((200) * 100 
//     );
//     setBackgroundProgress(prog);

//   },
//   (err) => console.log(err),
//   () => {
//     getDownloadURL(uploadTask.snapshot.ref)
//     .then((url) => console.log(url))
//   }

//   )
  
// }


// // https://www.youtube.com/watch?v=xUKIQAIOfrU

// const uploadFileNew = (e) => {
//   const userId = id.userID
//   let file = e.target.files[0]
//   setAppBackgroundImage(file);
//   let fileRef = RefStor(storeage, 'usersInformation/' + userId + "/" + "appBackground_" + userId  )

//   const uploadTask = uploadBytesResumable(fileRef, file)

//   uploadTask.on('state_changed', (snapshot) => {
//     const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) *100)
    
//     console.log("Upload is " + progress + "% done")
//     setBackgroundProgress(progress)
//   })
// }

// // const saveFileNew = (appBackgroundImage) => {
// //   const userId = id.userID
// //   let fileRef = RefStor(storeage, 'usersInformation/' + userId + "/" + userId )
// //   const uploadTask = uploadBytesResumable(fileRef, appBackgroundImage)

// //   uploadTask.on('state_changed', (snapshot) => {
// //     const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) *100)
    
// //     console.log("Upload is " + progress + "% done")
// //     setBackgroundProgress(progress)
// //   })
// // }


// // const closeModal = () => {
// //   setOpenUploadAppBackgroundModal(false)
// // }


// // For Modal 

// function openModal() {
//   setIsOpen(true);
// }

// function afterOpenModal() {
//   // references are now sync'd and can be accessed.
//   subtitle.style.color = '#f00';
// }













// UPLOAD IMAGE

// const uploadAppBackgroundImage = async( appBackgroundImage ) =>{

  
//   const userId = id.userID

//   // create a reference to the firbase storage
//   const storeNew = storeage
//   //let bikeId = bikeIdProp
//   //console.log('SHOW STORAGE', bikeIdProp);

//   const userIdNew = userId
//   //const userIdNew = authentication.currentUser.uid;
//   //let newBikeId = bikeId;
 
//   console.log('new User', userIdNew)
 
//  const helper = "'"
//  const helper2 = "/"
//   // REWRITTEN const ref = ref(db, 'bikes/' + userIdNew + newBikeRef)
//   const refNew = RefStor(storeNew, 'usersInformation/' + userIdNew + helper2 + userIdNew)

//   try{
//     // converting to blob
//     const db = database;

//     const blob = await ImageHelpers.prepareBlob(appBackgroundImage)
//     // correspoding to this thread, add +JSON.stringify - https://stackoverflow.com/questions/54510060/expo-react-native-there-was-a-problem-sending-log-messages
//     console.log('BLOB', +JSON.stringify(blob))
//     console.log("Blobbbb", blob)
//     //const snapshot = await refNew.put(blob)
//     // changed uploadBytes to uploadBytesResumable because of problem while uploading photo - changes made corresponding to this thread: https://github.com/firebase/firebase-js-sdk/issues/5848
//     await uploadBytesResumable(refNew, blob).then((snapshot) => {
//       console.log('Uploaded a blob or file!');
//     });
    
//     const bikeImage = {
      
//   };


// let downloadUrl = await getDownloadURL(refNew);
// console.log(downloadUrl)
// //const userIdNew = authentication.currentUser.uid;
 
// // const bikesTable = refDb(db, 'bikes/' + userIdNew + "/" + bikeIdProp + "/");
// // update(bikesTable, {
// //     bikePictureUser: downloadUrl,
// // }) 
  

// const userTable = ref(db, 'usersInformation/' + userIdNew + "/" );
// update(userTable, {
//   highlightPictureHeight: downloadUrl,
// }) 
  
  
  
 

//    blob.close()

//     return downloadUrl

//    // const bikeId = bikeIdProp;


//   }catch(error)
//   {
//     console.log(error)
//   }
// }



// function closeModal() {
//   setIsOpen(false);
// }


// function saveModal() {
//   setIsOpen(false);
//   //onFileChange()
//   // saveFileNew()
//   //uploadFile(appBackgroundImage)
//   //uploadAppBackgroundImage()
// }

// // For Highlight Picture Modal 

// function openHighlightModal() {
//   setHighlightModalIsOpen(true);
// }

// // function afterOpenModal() {
// //   // references are now sync'd and can be accessed.
// //   subtitle.style.color = '#f00';
// // }

// function closeHighlightModal() {
//   setHighlightModalIsOpen(false);
// }


// function saveHighlightModal() {
//   setHighlightModalIsOpen(false);
// }













  return (
    <div className="single">
      <Sidebar />
      
      <div className="singleContainer">
        <Navbar />
        <div style={{width: '100%',
        fontSize: 24,
        color: 'gray',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', margin:20}}>
        Qualified Sales Opportunity
        </div>
        <div className="top">
          <div className="left">
            <div className="editButton">Edit</div>
            <h1 className="title">QUALIFIED SALES OPPORTUNITY ID {userId}</h1>
            <div className="item">
              <img
                src="https://cdn-icons-png.flaticon.com/512/2972/2972185.png"
                alt=""
                className="itemImg"
              />
              <div className="details">
                <h1 className="itemTitle"></h1>
                <div className="detailItem">
                  <span className="itemKey">Potential Buyer:</span>
                  <span className="itemValue">{qSOData.potentialBuyerId}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Seller:</span>
                  <span className="itemValue">{qSOData.sellerId}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Interest Started On:</span>
                  <span className="itemValue">-</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Appointment Approval On:</span>
                  <span className="itemValue">-</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Test Riding Date:</span>
                  <span className="itemValue">-</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">AVAILABLE CHECK</span>
                  <span className="itemValue">TRUE</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">ARCHIVE QSO</span>
                </div>
                {/* <div className="detailItem">
                  <span className="itemKey">QSO STATE:</span>
                  <span className="itemValue">
                    -
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">-</span>
                </div> */}
              </div>
            </div>
          </div>






          <div className="right">
            <div style={{marginTop:35, marginBottom:40}}>QSO STATE - {qSOData.qsoState}</div>
            {/* <h1 className="title">QUALIFIED SALES OPPORTUNITY ID {userId}</h1> */}
            <div>
            
              <div className="details">
                <h1 className="itemTitle"></h1>


                { qSOData.qsoState === "INTERESTED" ?
                < InterestedQSODashboard />
                :
                <div></div>
                }

                { qSOData.qsoState === "WAITING FOR APPOINTMENT APPROVAL" ?
                < WaitingForAppointmentApprovalQSODashboard />
                :
                <div></div>
                }

                { qSOData.qsoState === "APPOINTMENT DENIED" ?
                < AppointmentDeniedQSODashboard />
                :
                <div></div>
                } 

                { qSOData.qsoState === "WAITING FOR LAST CALL" ?
                < WaitingForLastCallQSODashboard />
                :
                <div></div>
                }   

                { qSOData.qsoState === "APPOINTMENT LAST CALL" ?
                < AppointmentLastCallQSODashboard />
                :
                <div></div>
                } 

                { qSOData.qsoState === "APPOINTMENT APPROVAL" ?
                < AppointmentApprovalQSODashboard />
                :
                <div></div>
                } 

                { qSOData.qsoState === "TEST RIDING" ?
                < TestRidingQSODashboard />
                :
                <div></div>
                }     

                { qSOData.qsoState === "WAITING FOR DEAL VERIFICATION"?
                <div style={{ marginTop:70, marginBottom:70}}>

                            <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>

                            
                                <HourglassTopIcon style={{fontSize:140}}/>
                                <DirectionsBikeIcon style={{fontSize:140}}/>
                                <SellIcon style={{fontSize:140}}/>
                                <VerifiedUserIcon style={{fontSize:140, color:'#6fc276'}}/>


                            </div>

                </div>
                :
                <div></div>
                }
        


         

              </div>
            </div>
          </div>
         
         
 
        </div>
        {/* <div className="bottom">
        <h1 className="title">Bicycles</h1>
        
        </div> */}











        <div className="top">
        <div className="left">
        
       

                { qSOData.qsoState === "INTERESTED" ?
                <InterestedWorking 
                    bikeKey={bikeKey}
                    userIdParent={userIdParent}
                    qSODataParent={qSOData}
                />
                :
                <div></div>
                }

                { qSOData.qsoState === "WAITING FOR APPOINTMENT APPROVAL" ?
                <WaitingForAppointmentApprovalWorking 
                    bikeKey={bikeKey}
                    userIdParent={userIdParent}
                    qSODataParent={qSOData}
                />
                :
                <div></div>
                }

                { qSOData.qsoState === "APPOINTMENT DENIED" ?
                <AppointmentDeniedWorking 
                    bikeKey={bikeKey}
                    userIdParent={userIdParent}
                    qSODataParent={qSOData}
                />
                :
                <div></div>
                }


                { qSOData.qsoState === "WAITING FOR LAST CALL" ?
                <WaitingForLastCallWorking 
                    bikeKey={bikeKey}
                    userIdParent={userIdParent}
                    qSODataParent={qSOData}
                />
                :
                <div></div>
                }

                { qSOData.qsoState === "APPOINTMENT LAST CALL" ?
                <AppointmentLastCallWorking 
                    bikeKey={bikeKey}
                    userIdParent={userIdParent}
                    qSODataParent={qSOData}
                />
                :
                <div></div>
                }

                



                { qSOData.qsoState === "APPOINTMENT APPROVAL" ?
                <AppointmentApprovalWorking 
                    bikeKey={bikeKey}
                    userIdParent={userIdParent}
                    qSODataParent={qSOData}
                />
                :
                <div></div>
                }

                { qSOData.qsoState === "TEST RIDING" ?
                <TestRidingWorking 
                    bikeKey={bikeKey}
                    userIdParent={userIdParent}
                    qSODataParent={qSOData}
                />
                :
                <div></div>
                }

                { qSOData.qsoState === "WAITING FOR DEAL VERIFICATION" ?
                <WaitingForDealVerificationWorking 
                        bikeKey={bikeKey}
                        userIdParent={userIdParent}
                        qSODataParent={qSOData}
                /> 
                :
                <div></div>
                }





        </div>











        <div className="right" >
        
       
        <div style={{heigth:200}}>
        DEALER OPENING HOURS
        </div>
        </div>














        </div>




        

















      </div>
    </div>
  );
};

export default SingleQSOs;