import "./listSearchRequests.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
//import DatatableOpportunities from "../../components/datatable/DatatableOpportunities"
//import DatatableOpportunity from "../../components/datatableOpportunity/DatatableOpportunity"
import DatatableSearchRequests from "../../components/datatableSearchRequests/DatatableSearchRequests"

const ListSearchRequests = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        {/* <DatatableOpportunities/> */}
        <DatatableSearchRequests/>
      </div>
    </div>
  )
}

export default ListSearchRequests