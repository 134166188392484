import { useState, useEffect } from "react"
import Modal from 'react-modal';

import { useParams } from "react-router-dom";

import DoneAllIcon from '@mui/icons-material/DoneAll';
import BlockIcon from '@mui/icons-material/Block';

import AppointmentApprovalQSODashboard from "../qsoDashboard/AppointmentApprovalQSODashboard";
import AppointmentDeniedQSODashboard from "../qsoDashboard/AppointmentDeniedQSODashboard";
import AppointmentLastCallQSODashboard from "../qsoDashboard/AppointmentLastCallQSODashboard";
import WaitingForLastCallQSODashboard from "../qsoDashboard/WaitingForLastCallQSODashboard";

import { getDatabase, ref, onValue, get, update, set } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';

import { database, storeage } from "../../../config/config";


const WaitingForLastCallWorking = (props) => {



    const qSOId = useParams()
    const qSOIdParent = qSOId.qso

    const userIdParent = props.userIdParent
    const bikeKeyParent = props.bikeKey


    


    const [sendRequestNotificationToSellerModalIsOpen, setSendRequestNotificationToSellerModalIsOpen] = useState(false)
    const [sendAppointmentOptionsToUserModalIsOpen, setSendAppointmentOptionsToUserModalIsOpen] = useState(false)


    const [firstDateFromSeller, setFirstDateFromSeller] = useState("")
    const [firstTimeFromSeller, setFirstTimeFromSeller] = useState("")

    const [secondDateFromSeller, setSecondDateFromSeller] = useState("")
    const [secondTimeFromSeller, setSecondTimeFromSeller] = useState("")

    const [thirdDateFromSeller, setThirdDateFromSeller] = useState("")
    const [thirdTimeFromSeller, setThirdTimeFromSeller] = useState("")

    const [verifyFirstDate, setVerifyFirstDate] = useState(false)
    const [verifyFirstTime, setVerifyFirstTime] = useState(false)

    const [verifySecondDate, setVerifySecondDate] = useState(false)
    const [verifySecondTime, setVerifySecondTime] = useState(false)

    const [verifyThirdDate, setVerifyThirdDate] = useState(false)
    const [verifyThirdTime, setVerifyThirdTime] = useState(false)


    


  useEffect(()=> {
    // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
    console.log("dateFromUser inside",firstDateFromSeller)
        function isValidDate(firstDateFromSeller)
        {
            // First check for the pattern
            if(!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(firstDateFromSeller))
                return false;
                
              

            // Parse the date parts to integers
            var parts = firstDateFromSeller.split(".");
            
            var day = parseInt(parts[0], 10);
            var month = parseInt(parts[1], 10);
            var year = parseInt(parts[2], 10);

            // Check the ranges of month and year
            if(year < 1000 || year > 3000 || month == 0 || month > 12)
                return false;
            

            var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

            // Adjust for leap years
            if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
                monthLength[1] = 29;

            // Check the range of the day
            return day > 0 && day <= monthLength[month - 1];

  
        };
        let isDateTrue = isValidDate(firstDateFromSeller)
        setVerifyFirstDate(isDateTrue)
        console.log(isDateTrue)

    },[firstDateFromSeller])


  useEffect(()=> {
        // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
        console.log("dateFromUser inside",secondDateFromSeller)
            function isValidDate(secondDateFromSeller)
            {
                // First check for the pattern
                if(!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(secondDateFromSeller))
                    return false;
                    
                  
    
                // Parse the date parts to integers
                var parts = secondDateFromSeller.split(".");
                
                var day = parseInt(parts[0], 10);
                var month = parseInt(parts[1], 10);
                var year = parseInt(parts[2], 10);
    
                // Check the ranges of month and year
                if(year < 1000 || year > 3000 || month == 0 || month > 12)
                    return false;
                
    
                var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
    
                // Adjust for leap years
                if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
                    monthLength[1] = 29;
    
                // Check the range of the day
                return day > 0 && day <= monthLength[month - 1];
    
      
            };
            let isDateTrue = isValidDate(secondDateFromSeller)
            setVerifySecondDate(isDateTrue)
            console.log(isDateTrue)
    
    },[secondDateFromSeller])


   useEffect(()=> {
        // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
        console.log("dateFromUser inside",thirdDateFromSeller)
            function isValidDate(thirdDateFromSeller)
            {
                // First check for the pattern
                if(!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(thirdDateFromSeller))
                    return false;
                    
                  
    
                // Parse the date parts to integers
                var parts = thirdDateFromSeller.split(".");
                
                var day = parseInt(parts[0], 10);
                var month = parseInt(parts[1], 10);
                var year = parseInt(parts[2], 10);
    
                // Check the ranges of month and year
                if(year < 1000 || year > 3000 || month == 0 || month > 12)
                    return false;
                
    
                var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
    
                // Adjust for leap years
                if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
                    monthLength[1] = 29;
    
                // Check the range of the day
                return day > 0 && day <= monthLength[month - 1];
    
      
            };
            let isDateTrue = isValidDate(thirdDateFromSeller)
            setVerifyThirdDate(isDateTrue)
            console.log(isDateTrue)
    
    },[thirdDateFromSeller])


    useEffect(() => {
        function validateTime (time) {
            console.log(firstTimeFromSeller)
            const timeReg = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/
            console.log(time.match(timeReg))
            //return time.match(timeReg)
            
            if (time.match(timeReg) === null){
        
                setVerifyFirstTime(false)
            }
            else {
              
                setVerifyFirstTime(true)
            }
          }
          validateTime(firstTimeFromSeller)
    },[firstTimeFromSeller])

    useEffect(() => {
        function validateTime (time) {
            console.log(secondTimeFromSeller)
            const timeReg = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/
            console.log(time.match(timeReg))
            //return time.match(timeReg)
            
            if (time.match(timeReg) === null){
        
                setVerifySecondTime(false)
            }
            else {
              
                setVerifySecondTime(true)
            }
          }
          validateTime(secondTimeFromSeller)
    },[secondTimeFromSeller])

    useEffect(() => {
        function validateTime (time) {
            console.log(thirdTimeFromSeller)
            const timeReg = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/
            console.log(time.match(timeReg))
            //return time.match(timeReg)
            
            if (time.match(timeReg) === null){
        
                setVerifyThirdTime(false)
            }
            else {
              
                setVerifyThirdTime(true)
            }
          }
          validateTime(thirdTimeFromSeller)
    },[thirdTimeFromSeller])



    const openRequestNotificationModal = () => {
        setSendRequestNotificationToSellerModalIsOpen(true)
      }
    
    
      const closeRequestNotificationModal = () => {
        setSendRequestNotificationToSellerModalIsOpen(false)
      }
    




      const openAppointmentOptionsModal = () => {
        setSendAppointmentOptionsToUserModalIsOpen(true)
    }

    const closeAppointmentOptionsModal = () => {
        setSendAppointmentOptionsToUserModalIsOpen(false)
    }


    const updateApproveAppointment = () => {
    
        const db = database;
    
        const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent );
        const bikeTableThirdChild = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent + "/" + "meetingOpportunityObject/" + "additionalMeetingOpportunityTwo");

            update(bikeTable, {
                qsoState:'APPOINTMENT LAST CALL',
            })
            
            update(bikeTableThirdChild, {
                dateFirstOption:firstDateFromSeller,
                timeFirstOption:firstTimeFromSeller,
                dateSecondOption:secondDateFromSeller,
                timeSecondOption:secondTimeFromSeller,
                dateThirdOption:thirdDateFromSeller,
                timeThirdOption:thirdTimeFromSeller,
            })
            setSendAppointmentOptionsToUserModalIsOpen(false)
      }



        const handleFirstChangeDate =(e) => {
            setFirstDateFromSeller(e.target.value)
        } 

        const handleFirstChangeTime =(e) => {
            setFirstTimeFromSeller(e.target.value)
        }
        
        const handleSecondChangeDate =(e) => {
            setSecondDateFromSeller(e.target.value)
        } 

        const handleSecondChangeTime =(e) => {
            setSecondTimeFromSeller(e.target.value)
        }

        const handleThirdChangeDate =(e) => {
            setThirdDateFromSeller(e.target.value)
        } 

        const handleThirdChangeTime =(e) => {
            setThirdTimeFromSeller(e.target.value)
        }









    return (
          <div>
        
                 

                {sendAppointmentOptionsToUserModalIsOpen === true ? 


                    <div>
                        <Modal
                            isOpen={sendAppointmentOptionsToUserModalIsOpen}
                            //onAfterOpen={afterOpenModal}
                            onRequestClose={closeAppointmentOptionsModal}
                            style={{ content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                        
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            },}}
                            contentLabel="Example Modal"
                        >

                            <div style={{height:890,width:900,}}>
                                <div className="title">
                                    <h1 style={{textAlign:'center'}}>Send Appointment Options to User</h1>
                                    <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                        Send a Notification to the seller to remind it to answer the requested appointment option made by the user:
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        User Email
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        lucas.deuker@gmc.dde
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        User Phone NUmber
                                    </p>
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        +49 12893 1839120 
                                    </p>

                                    
                                    
                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                    Add 3 options here:
                </p>
                {/* <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                    1. option
                </p> */}





                                                       
                                                        <div style={{marginLeft:120, display:'flex', flexDirection:'row'}}>
                                                            <div style={{position:'absolute', left: 110, top: 365}}>
                                                                <h1>
                                                                    1.
                                                                </h1>
                                                            </div>
                                                            <div style={{display:'flex', flexDirection:'row'}}>
                                    
                                                                <div>
                                                                    
                                                                    <label>
                                                                        <input 
                                                                            type="text" 
                                                                            placeholder="DD.MM.YYYY"
                                                                            //value="100" 
                                                                            defaultValue={firstDateFromSeller}
                                                                            onChange={handleFirstChangeDate} 
                                                                            style={{width:200, height:40,  font:'caption',fontSize:28, fontWeight:'900', font:'caption'}}
                                                                        />
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    { verifyFirstDate === false ?
                                                                    <div>
                                                                    <BlockIcon style={{fontSize:29, color:'red', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                   
                                                                    :
                                                                    <div>
                                                                    <DoneAllIcon style={{fontSize:29, color:'green', marginTop:8, marginLeft:10}}/>
                                                                </div>
                                                                    
                                                                    }
                                                                </div>
                                                             
                                                            </div>
                                                       

                                                        <div style={{display:'flex', flexDirection:'row', marginLeft: 30}}>
                                                                <div>
                                                                    <label>
                                                                        <input 
                                                                            type="text" 
                                                                            placeholder="HH:MM"
                                                                            //value="100" 
                                                                            onChange={handleFirstChangeTime} 
                                                                            defaultValue={firstTimeFromSeller}
                                                                            style={{width:160, height:40,  font:'caption', fontSize:28, fontWeight:'900',}}
                                                                        />
                                                                    </label>
                                                                </div>

                                                                <div>
                                                                    { verifyFirstTime === false ?
                                                                    <div>
                                                                    <BlockIcon style={{fontSize:29, color:'red', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                   
                                                                    :
                                                                    <div>
                                                                    <DoneAllIcon style={{fontSize:29, color:'green', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                    }
                                                                </div>
                                                        </div>
                                                        </div>






                                                        <div style={{marginLeft:120, display:'flex', flexDirection:'row'}}>
                                                            <div style={{position:'absolute', left: 105, top: 415}}>
                                                                <h1>
                                                                    2.
                                                                </h1>
                                                            </div>
                                                            <div style={{display:'flex', flexDirection:'row'}}>
                                    
                                                                <div>
                                                                    
                                                                    <label>
                                                                        <input 
                                                                            type="text" 
                                                                            placeholder="DD.MM.YYYY"
                                                                            //value="100" 
                                                                            defaultValue={secondDateFromSeller}
                                                                            onChange={handleSecondChangeDate} 
                                                                            style={{width:200, height:40,  font:'caption',fontSize:28, fontWeight:'900', font:'caption'}}
                                                                        />
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    { verifySecondDate === false ?
                                                                    <div>
                                                                    <BlockIcon style={{fontSize:29, color:'red', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                   
                                                                    :
                                                                    <div>
                                                                    <DoneAllIcon style={{fontSize:29, color:'green', marginTop:8, marginLeft:10}}/>
                                                                </div>
                                                                    
                                                                    }
                                                                </div>
                                                             
                                                            </div>
                                                       

                                                        <div style={{display:'flex', flexDirection:'row', marginLeft: 30}}>
                                                                <div>
                                                                    <label>
                                                                        <input 
                                                                            type="text" 
                                                                            placeholder="HH:MM"
                                                                            //value="100" 
                                                                            onChange={handleSecondChangeTime} 
                                                                            defaultValue={secondTimeFromSeller}
                                                                            style={{width:160, height:40,  font:'caption', fontSize:28, fontWeight:'900',}}
                                                                        />
                                                                    </label>
                                                                </div>

                                                                <div>
                                                                    { verifySecondTime === false ?
                                                                    <div>
                                                                    <BlockIcon style={{fontSize:29, color:'red', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                   
                                                                    :
                                                                    <div>
                                                                    <DoneAllIcon style={{fontSize:29, color:'green', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                    }
                                                                </div>
                                                        </div>
                                                        </div>

                                                        <div style={{marginLeft:120, display:'flex', flexDirection:'row'}}>
                                                            <div style={{position:'absolute', left: 106, top: 460}}>
                                                                <h1>
                                                                    3.
                                                                </h1>
                                                            </div>
                                                            <div style={{display:'flex', flexDirection:'row'}}>
                                    
                                                                <div>
                                                                    
                                                                    <label>
                                                                        <input 
                                                                            type="text" 
                                                                            placeholder="DD.MM.YYYY"
                                                                            //value="100" 
                                                                            defaultValue={thirdDateFromSeller}
                                                                            onChange={handleThirdChangeDate} 
                                                                            style={{width:200, height:40,  font:'caption',fontSize:28, fontWeight:'900', font:'caption'}}
                                                                        />
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    { verifyThirdDate === false ?
                                                                    <div>
                                                                    <BlockIcon style={{fontSize:29, color:'red', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                   
                                                                    :
                                                                    <div>
                                                                    <DoneAllIcon style={{fontSize:29, color:'green', marginTop:8, marginLeft:10}}/>
                                                                </div>
                                                                    
                                                                    }
                                                                </div>
                                                             
                                                            </div>
                                                       

                                                        <div style={{display:'flex', flexDirection:'row', marginLeft: 30}}>
                                                                <div>
                                                                    <label>
                                                                        <input 
                                                                            type="text" 
                                                                            placeholder="HH:MM"
                                                                            //value="100" 
                                                                            onChange={handleThirdChangeTime} 
                                                                            defaultValue={thirdTimeFromSeller}
                                                                            style={{width:160, height:40,  font:'caption', fontSize:28, fontWeight:'900',}}
                                                                        />
                                                                    </label>
                                                                </div>

                                                                <div>
                                                                    { verifyThirdTime === false ?
                                                                    <div>
                                                                    <BlockIcon style={{fontSize:29, color:'red', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                   
                                                                    :
                                                                    <div>
                                                                    <DoneAllIcon style={{fontSize:29, color:'green', marginTop:8, marginLeft:10}}/>
                                                                    </div>
                                                                    }
                                                                </div>
                                                        </div>
                                                        </div>

















                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        Click here to find E-Mail or Text Message Templates
                                    </p>

                                    <div style={{marginLeft:90, marginTop:40, marginBottom:40}}>
                                        <AppointmentLastCallQSODashboard/>
                                    </div>


                                    <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                        After the Notification is sent - Click on "Notification Sent" so the state for this QSO will change to the state of "APPOINTMENT LAST CALL"
                                    </p>

                                    <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                    <div>
                                        <button  onClick={closeAppointmentOptionsModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                    </div>
                                    <div>
                                        <button  onClick={updateApproveAppointment} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Notification Sent</button>
                                    </div>
                                    </div>
                                </div>

                                <div>

                                

                                

                                </div>
                            </div>

                        </Modal>
                    </div>

                    :
                    <div></div>


                    }













                    {sendRequestNotificationToSellerModalIsOpen === true ? 


                        <div>
                            <Modal
                                isOpen={sendRequestNotificationToSellerModalIsOpen}
                                //onAfterOpen={afterOpenModal}
                                onRequestClose={closeRequestNotificationModal}
                                style={{ content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                            
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                },}}
                                contentLabel="Example Modal"
                            >

                                <div style={{height:500,width :700,}}>
                                    <div className="title">
                                        <h1 style={{textAlign:'center'}}>Send Appointment Request to Seller</h1>
                                        <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                            Send a Notification to the seller to remind it to answer the requested appointment option made by the user:
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            Seller Email
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            lucas.deuker@gmc.dde
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            Seller Phone NUmber
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            +49 12893 1839120 
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            Click here to find E-Mail or Text Message Templates
                                        </p>
                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                            After the Notification is sent - Click on "Notification Sent" so the state for this QSO will change to an state of "APPOINTMENT LAST CALL"
                                        </p>


                                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                        <div>
                                            <button  onClick={closeRequestNotificationModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                        </div>
                                        <div>
                                            <button  onClick={closeRequestNotificationModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 180, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Notification Sent</button>
                                        </div>
                                        </div>
                                    </div>

                                    <div>

                                    

                                    

                                    </div>
                                </div>

                            </Modal>
                        </div>

                        :
                        <div></div>


                    }




























                                <div>
                                


                                <div className="search">
                                
                        

                                <div style={{display: 'flex', flexDirection:'row', marginBottom: 10,}}>

                                <div>
                                    <div style={{marginRight:40, marginLeft:10}}>
                                    
                                    </div>
                                </div>
                                
                                    <div>
                                    <div style={{marginTop:0, marginBottom:13}}>
                                    <div style={{fontSize:22, fontWeight:'1000'}}>
                                    WAITING FOR LAST CALL
                                    </div>
                                    <div style={{marginTop:10, marginBottom: 8}}> 
                                        1. ASKING THE SELLER ABOUT THREE MORE APPOINTMENT OPTIONS
                                    </div>


                                    <button 
                                        onClick={openRequestNotificationModal}
                                        style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#e69b00', border:'1px solid black', borderRadius: 20, marginBottom: 15, marginTop:12}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',}}>
                                        <div style={{fontSize:17, fontWeight:'900', color:'black',}}>
                                        SEND APPOINTMENT REQUEST TO SELLER
                                        </div>
                                    </div>
                                    </button>














                                
                                    <div style={{marginTop:54, marginBottom: 8}}>
                                        2. SEND APPOINTMENT OPTIONS TO USER 
                                    </div>
                                

              


                                    <button 
                                        onClick={openAppointmentOptionsModal}
                                        style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15,}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                        <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                                        SEND OPTIONS TO USER
                                        </div>
                                    </div>
                                    </button>

                                    {/* <div style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15,}}>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 7}}> */}
                                        {/* <div style={{fontSize:14, fontWeight:'900', color:'black',}}>
                                        CHECKBOX: SEND REMINDER VIA EMAIL
                                        </div> */}
                                    {/* </div>
                                    </div> */}

                                
                                    
                                    
                                    </div>

                                
                                </div>
                                </div>  











                                </div>
                                </div>

                        
  
          </div>
    )
  }
  
  export default WaitingForLastCallWorking