import React, { useEffect, useState }  from "react";
import Modal from 'react-modal';

import { useParams } from "react-router-dom";

import { getDatabase, ref, onValue, get, update, set } from "firebase/database";

import 'firebase/storage';
import 'firebase/database';

import { database, storeage } from "../../../config/config";


const DealWorking = (props) => {

    const qSOId = useParams()
    const qSOIdParent = qSOId.deals
  
    const userIdParent = props.userIdParent
    const bikeKeyParent = props.bikeKey


    const [sendFirstEmailToUserModalIsOpen, setSendFirstEmailToUserModalIsOpen] = useState(false)
    const [directAppointmentModalIsOpen, setDirectAppointmentModalIsOpen] = useState(false)


    console.log("userIdParent", userIdParent)

    console.log("qSOIdParent", qSOIdParent)

    const openSendFirstEmailModal = () => {
        setSendFirstEmailToUserModalIsOpen(true)
      }
    

      const closeSendFirstEmailModal = () => {
        setSendFirstEmailToUserModalIsOpen(false)
      }

    //   const openDirectAppointmentModal = () => {
    //     setDirectAppointmentModalIsOpen(true)
    //   }

    //   const closeDirectAppointmentModal = () => {
    //     setDirectAppointmentModalIsOpen(false)
    //   }

    const updateDealFinalize = () => {
    
        const db = database;
    
        const bikeTable = ref(db, 'qualifiedSalesOpportunity/' + userIdParent + "/" + qSOIdParent );
            update(bikeTable, {
              qsoState:'DEAL FINALIZED',
            }) 
            setSendFirstEmailToUserModalIsOpen(false)
      }



    return (
          <div>
        


           








                        {sendFirstEmailToUserModalIsOpen === true ? 


                        <div>
                            <Modal
                                isOpen={sendFirstEmailToUserModalIsOpen}
                                //onAfterOpen={afterOpenModal}
                                onRequestClose={closeSendFirstEmailModal}
                                style={{ content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                            
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                },}}
                                contentLabel="Example Modal"
                            >

                                <div style={{height:390,width :700,}}>
                                    <div className="title">
                                        <h1 style={{textAlign:'center', paddingLeft:120, paddingRight:120}}>Switch Bicycle into Users Passport</h1>
                                        <p style={{margin:20, marginLeft: 80, paddingRight:80}}>
                                           By Clicking "Switch Bicycle" you switch the bicycle from the former seller into the users account
                                        </p>
                                        
                                      







                                        <p style={{ marginLeft: 80, marginTop: 20, paddingRight:80}}>
                                         This Step will automatically switch the State to "DEAL FINALIZED" after you press "Switch Bicycle". 
                                        </p>

                                        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-evenly'}}>
                                        <div>
                                            <button  onClick={closeSendFirstEmailModal} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "red", borderRadius: 20, color: 'white', fontWeight: 400 }}>Close</button>
                                        </div>
                                        <div>
                                            <button  onClick={updateDealFinalize} style={{marginTop: 30, marginRight: 0, height: 50, width: 150, backgroundColor: "blue", borderRadius: 20, color: 'white', fontWeight: 400 }}>Switch Bicycle</button>
                                        </div>
                                        </div>
                                    </div>

                                    <div>

                                    

                                    

                                    </div>
                                </div>

                            </Modal>
                        </div>

                        :
                        <div></div>


                        }












                 
                <div>
                


                <div className="search">
                
        

                <div style={{display: 'flex', flexDirection:'row', marginBottom: 10,}}>

                <div>
                    <div style={{marginRight:40, marginLeft:10}}>
                    
                    </div>
                </div>
                
                    <div>
                    <div style={{marginTop:0, marginBottom:13}}>
                    <div style={{fontSize:22, fontWeight:'1000'}}>
                    DEAL
                    </div>
                    <div style={{marginTop:10, marginBottom: 8}}>
                        SWITCH THE BICYCLE FROM THE FORMER SELLERS PASSPORT INTO THE USERS PASSPORT
                    </div>


                    <button 
                        onClick={openSendFirstEmailModal}
                        style={{width: 350, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15, marginTop:12}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                        SWITCH BICYCLE TO USERS PASSPORT
                        </div>
                    </div>
                    </button>

                    {/* <div style={{width: 250, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'#1870b5', border:'1px solid white', borderRadius: 20, marginBottom: 15,}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop: 7}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'white',}}>
                        SEND PUSH NOTIFICATION
                        </div>
                    </div>
                    </div> */}


                    {/* <div style={{marginTop:10, marginBottom: 8}}>
                        SEND APPOINTMENT TO USER BASED ON SELLERS AVAILABLE MEETING TIME
                    </div>


                    <button 
                         onClick={openDirectAppointmentModal}
                        style={{width: 250, height: 40, justifyContent:'center', alignItems:'center', backgroundColor:'white', border:'1px solid #1870b5', borderRadius: 20, marginBottom: 15,}}>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center',}}>
                        <div style={{fontSize:17, fontWeight:'900', color:'#1870b5',}}>
                        SEND APPOINTMENT TO USER
                        </div>
                    </div>
                    </button> */}

                
                    
                    
                    </div>

                
                </div>
                </div>  











                </div>
                </div>

  
  
          </div>
    )
  }
  
  export default DealWorking